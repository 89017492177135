import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { awsUrl, triggerGAEvent } from "../../helpers";
import Button from "../../common/Elements/Button";

import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";
import { useGSAP } from "../../gsap/react";

gsap.registerPlugin(useGSAP, SplitText, useGSAP);

const UnlockedPortalLanding = ({ portal, className }) => {
  const headlineTl = useRef();

  useGSAP(() => {
    let headlineRef = new SplitText("#featured-headline", { type: "chars, words" });
    let bodyRef = new SplitText("#featured-copy span", {
      type: "lines",
      linesClass: "line line++"
    });

    function splitRevert() {
      bodyRef.revert();
    }

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#featured-portal"
          // markers: true
        }
      })
      .fromTo("#unlocked-bg", { opacity: 0 }, { opacity: 1 }, "0")
      .fromTo("#feature-icon", { opacity: 0, marginTop: "10px" }, { opacity: 1, marginTop: 0 }, "0")
      .from(
        headlineRef.chars,
        {
          duration: 0.65,
          y: 20,
          autoAlpha: 0,
          stagger: 0.03
        },
        "<"
      )
      .from(
        bodyRef.lines,
        {
          duration: 0.65,
          y: 10,
          autoAlpha: 0,
          stagger: 0.05,
          onComplete: splitRevert
        },
        "<+=1"
      )
      .fromTo("#featured-cta", { opacity: 0 }, { opacity: 1 }, "<+=0.5");

    if (morePortalName && morePortalLink) {
      headlineTl.current.fromTo("#featured-caption", { opacity: 0 }, { opacity: 1 }, "<");
    }
  }, {});

  const portalData = {
    "adult-friendly": {
      image: `${awsUrl}/media/portals/adult-friendly/bg-unlocked@2x.jpg?v=2`,
      portalKey: "adult-friendly",
      portalName: "Calypso Lagoon",
      portalColor: "bg-[#ED196E]",
      portalLink: "/portals/calypso-lagoon",
      copy: "We’re excited to reveal Calypso Lagoon, the adult-friendly Portal at Celebration Key™. With a full-service restaurant, swim up bar, DJ stage and more, no matter what you choose, you’ll find what you need for an unforgettable day in paradise.",
      icon: `${awsUrl}/media/portals/adult-friendly/icon.svg`,
      morePortalName: "welcome Portal, Paradise Plaza",
      morePortalLink: "/portals/retail"
    },
    retail: {
      image: `${awsUrl}/media/portals/retail/unlocked/retail-still.jpg?v=2`,
      portalKey: "retail",
      portalName: "Lokono Cove",
      portalColor: "bg-[#00c8d3]",
      portalLink: "/portals/lokono-cove",
      copy: "We’re excited to reveal Lokono Cove, the retail Portal at Celebration Key™. Here you can celebrate Bahamian culture, arts, and flavors as you shop locally crafted, authentic Bahamian goods.",
      icon: `${awsUrl}/media/portals/retail/icon.svg`,
      morePortalName:
        "Take a first look at the newly unlocked adult-friendly Portal, Calypso Lagoon",
      morePortalLink: "/portals/calypso-lagoon"
    },
    "family-friendly": {
      image: `${awsUrl}/media/portals/family-friendly/unlocked/splashpad.jpg?v=2`,
      portalKey: "family-friendly",
      portalName: "Starfish Lagoon",
      portalColor: "bg-[#6470B5]",
      portalLink: "/portals/starfish-lagoon",
      copy: "We’re excited to reveal <strong>Starfish Lagoon</strong> at Celebration Key™. With a freshwater lagoon, splash pad, and beach area, it’s a place for the whole family to discover paradise and make memories. ",
      icon: `${awsUrl}/media/portals/family-friendly/icon.svg`,
      morePortalName: "Check out the adult-friendly Portal, Calypso Lagoon",
      morePortalLink: "/portals/calypso-lagoon"
    },
    "private-club": {
      image: `${awsUrl}/media/portals/private-club/pearl-cove-bg@2x.jpg`,
      portalKey: "private-club",
      portalPreName: "Adults-Only Private Club",
      portalName: "Pearl Cove",
      portalColor: "bg-ck-private",
      portalLink: "/portals/pearl-cove",
      copy: "We’re excited to reveal Pearl Cove Beach Club at Celebration Key<sup>&trade;</sup>. From its inviting infinity pool to stunning beach views, this is the ultimate paradise experience <span class='inline-block'>for guests 18+.</span>",
      icon: `${awsUrl}/media/portals/private-club/icon.svg`,
      hideImageMb: true
    }
  };

  const {
    image,
    portalName,
    portalColor,
    portalLink,
    copy,
    icon,
    morePortalName,
    morePortalLink,
    portalKey,
    portalPreName,
    hideImageMb
  } = portalData[portal];

  return (
    <section id="featured-portal" data-tag="section_3B_fp-private-club" data-pixel="">
      <div className={`w-full h-auto lg:h-screen relative overflow-hidden ${className}`}>
        <div className="flex flex-col lg:flex-none lg:flex-row relative w-full h-full lg:flex justify-end">
          <div
            className={`lg:absolute bg-cover md:h-full w-full bg-center ${
              hideImageMb ? "hidden lg:block" : "h-[37%]"
            }`}
            style={{ backgroundImage: "url(" + image + ")" }}
          />

          <div
            id="unlocked-bg"
            className="z-10 w-full flex-grow lg:flex-none lg:w-[56%] pb-10 lg:pb-0 h-auto md:flex bg-white lg:bg-white/75 items-center justify-center"
          >
            <div className="text-center text-dark-blue px-[30px]">
              <div className="flex flex-col lg:flex-row items-center justify-center lg:mb-2">
                <div id="featured-headline" className="pt-[25px] lg:pt-0">
                  <div
                    id="feature-icon"
                    className={`mx-auto text-center ${hideImageMb ? "hidden lg:block" : ""}`}
                  >
                    <img
                      src={icon}
                      alt={`${portalName} Icon`}
                      className=" h-[60px] lg:h-[83px] mx-auto mb-3"
                      aria-hidden="true"
                    />
                  </div>

                  <div
                    className="text-[20px] uppercase font-semibold text-dark-blue leading-[25px]"
                    aria-hidden="true"
                  >
                    Just unlocked:
                  </div>
                  <div
                    className="text-[20px] uppercase font-semibold text-dark-blue leading-[25px]"
                    aria-hidden="true"
                  >
                    {portalKey !== "private-club" && `${portalKey} portal`}
                  </div>
                  <h2
                    className="text-[35px] lg:text-[55px] font-bold text-millik lg:-mt-3 leading-tightest"
                    aria-hidden="true"
                    dangerouslySetInnerHTML={{ __html: `${portalName}` }}
                  ></h2>
                  {portalName === "Pearl Cove" && (
                    <h2
                      className="text-[28px] lg:text-[45px] font-bold text-millik mb-3 -mt-3 leading-none"
                      aria-hidden="true"
                    >
                      Beach Club
                    </h2>
                  )}
                </div>
              </div>
              <span className="sr-only">
                Just unlocked: {portalPreName ? portalPreName : portalKey} portal
              </span>
              <span className="sr-only">
                {" "}
                {portalName} {portalName === "Pearl Cove" && "Beach Club"}
              </span>
              <p
                id="featured-copy"
                className="text-[16px] leading-[22px] lg:text-[19px] lg:leading-[25px] mb-[10px] lg:mb-[25px]"
              >
                <span dangerouslySetInnerHTML={{ __html: `${copy}` }} />
              </p>
              <Link
                id="featured-cta"
                to={portalLink}
                onClick={() => {
                  triggerGAEvent("section_3B_fp-link_1-cl");
                }}
              >
                <Button color={portalColor} className={portalColor + ` px-10 lg:px-20`}>
                  {/*Explore Pearl Cove <span className="inline-block">Beach Club</span>*/}
                  Explore {portalName !== "Pearl Cove" && portalName}{" "}
                </Button>
              </Link>
              {morePortalName && morePortalLink && (
                <>
                  {" "}
                  <p
                    id="featured-caption"
                    className="text-[16px] leading-[22px] lg:text-[19px] lg:leading-[25px] mt-6 px-2"
                  >
                    Looking for more?&nbsp;
                    <Link
                      to={morePortalLink}
                      className="font-semibold"
                      onClick={() => {
                        triggerGAEvent("section_3B_fp-link_2-pp");
                      }}
                      dangerouslySetInnerHTML={{ __html: `${morePortalName}&nbsp;»` }}
                    ></Link>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

UnlockedPortalLanding.prototype = {
  portal: PropTypes.string.isRequired
};

export default UnlockedPortalLanding;
