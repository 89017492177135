/** @format */
import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import { SweepstakesProvider } from "../context/SweepstakesContext";
import { scrollTo, triggerGAPageViewEvent } from "../helpers.js";
import { ScrollTrigger } from "../gsap/ScrollTrigger";

import NavBar from "../components/NavBar";
import { CountdownToOpening, Intro } from "../components/Intro";
import DiscoverYourParadise from "../components/DiscoverYourParadise";
import CompareSlider from "../components/CompareSlider.jsx";
import Quiz from "../components/Quiz";
import Preloader from "../components/Preloader.jsx";
import FeatureVideo from "../components/FeatureVideo.jsx";
import Sweepstakes from "../components/Sweepstakes";
import Footer from "../components/Footer";
import ScrollToAnchor from "../common/ScrollToAnchor";
import UnlockedPortalLanding from "../components/UnlockedPortal/UnlockedPortalLanding";
import { useZoomDetector } from "../hooks/useZoomDetector";
import { useScrollDetectors } from "../hooks/useScrollDetectors";
import { useSectionPixel } from "../hooks/useSectionPixel";

function Home() {
  const [showPreloader, setShowPreloader] = useState(false);

  const { setVifpNumber, isTrade, setCurrentPage, isPromoOver } = useUserContext();
  const [searchParams] = useSearchParams();
  const [hashChange, setHashChange] = useState(false);
  const [hash, setHash] = useState(null);

  setCurrentPage("home");

  useScrollDetectors("home", showPreloader);

  useSectionPixel(isTrade);

  const { isZoom, zoomStyles } = useZoomDetector();

  useEffect(() => {
    if (searchParams.get("vifpNum")) {
      setVifpNumber(searchParams.get("vifpNum"));
    }
  }, [searchParams, setVifpNumber]);

  useEffect(() => {
    if (window.location.hash) {
      if (
        window.location.hash === "#section-portals" ||
        window.location.hash === "#section-destination" ||
        window.location.hash === "#section-map"
      ) {
        window.location.hash = "#section-discover";
      }

      triggerGAPageViewEvent(window.location.hash);
      setShowPreloader(false);
      setHashChange(true);
      let hash = window.location.hash.substring(1);
      setHash(hash);

      setTimeout(() => {
        ScrollTrigger.refresh();
        scrollTo(hash);
      }, 450);
    } // hash found
  }, [showPreloader]);

  return (
    <div className={`App ${showPreloader && "overflow-hidden h-screen "} ${zoomStyles}`}>
      <main>
        <div id="ttd-pixel">
          <img
            height="1"
            width="1"
            style={{ borderStyle: `none` }}
            alt=""
            src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:oq3ul6b&fmt=3"
          />
        </div>
        <ScrollToAnchor />
        {showPreloader && <Preloader onComplete={() => setShowPreloader(false)} />}
        <NavBar />
        <Intro pageLoaded={!showPreloader} isZoom={isZoom} hashChange={hashChange} hash={hash} />
        <DiscoverYourParadise />
        <UnlockedPortalLanding portal="private-club" className="h-auto" />
        {!isPromoOver && (
          <SweepstakesProvider>
            <Sweepstakes />
          </SweepstakesProvider>
        )}
        <CountdownToOpening />
        <Quiz />
        <CompareSlider />
        <FeatureVideo />
      </main>
      <Footer />
    </div>
  );
}

export default Home;
