import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useUserContext } from "../../context/UserContext";
import { useSweepstakesContext } from "../../context/SweepstakesContext";
import { postEmail } from "../../services/apiClient";
import { awsUrl, scrollTo, triggerFloodLightEvent, triggerGAEvent } from "../../helpers";

import Input from "../../common/Elements/Input";
import Button from "../../common/Elements/Button";
import InputError from "../../common/Elements/InputError";
import { useGSAP } from "../../gsap/react";
import SplitText from "../../gsap/SplitText";
import { gsap } from "../../gsap";

const EmailForm = () => {
  const { setEnteredPromo, quizResultId, setUserId, isTrade } = useUserContext();
  const { setUserEmail, setShowSection, setCaptcha, gaCodes } = useSweepstakesContext();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    setError
  } = useForm();

  const validation = {
    email: { required: "Email is required" }
  };

  const [submitting, setSubmitting] = useState(false);

  const checkEmail = (data) => {
    if (!submitting) {
      data.gaCodes = gaCodes;
      data.quizResultId = quizResultId;
      data.is_trade = isTrade;

      triggerGAEvent("home_sweeps-continue");

      setSubmitting(true);

      postEmail(data)
        .then((response) => {
          if (response.status === 201) {
            // Entry completed successfully

            setShowSection(response.data.validEntry ? "thank-you" : "already-entered");
            if (response.data.validEntry) triggerGAEvent("entered");
            setUserId(response.data.userId);
            scrollTo("sweeps");
            setEnteredPromo(true);
          } else {
            // Something went wrong
            setError("email", { message: "Something went wrong. Please refresh try again." });
          }
          setSubmitting(false);
        })
        .catch((e) => {
          if (e.response) {
            const { response } = e;
            if (
              response.status === 422 &&
              response.data.errors &&
              Object.keys(response.data.errors).length > 0
            ) {
              // Backend validation errors
              Object.keys(response.data.errors).forEach((key) => {
                setError(key, { type: "server", message: response.data.errors[key][0] });
              });
            } else if (response.status === 409 && response.data.error === "email_not_found") {
              // No email found, show registration form
              setUserEmail(data.email);
              setCaptcha(response.data.captcha);
              scrollTo("sweeps");
              setShowSection("registration");

              if (!isTrade) {
                triggerFloodLightEvent("carni046+unique");
                document.getElementById("ttd-pixel").innerHTML =
                  '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:rtnffuk&fmt=3"/>';
              }
            } else {
              // Something went wrong
              setError("email", { message: "Something went wrong. Please refresh try again." });
            }
          } else {
            setError("email", { message: "Something went wrong. Please refresh try again." });
          }
          setSubmitting(false);
        });
    }
  };

  const tl = useRef();

  useGSAP(() => {
    let headlineRef = new SplitText("#sweeps-headline", { type: "chars, words" });

    tl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#sweeps-headline"
        }
      })
      .from(
        headlineRef.chars,
        {
          duration: 0.65,
          x: 10,
          autoAlpha: 0,
          stagger: 0.05
        },
        "<"
      )
      .fromTo(
        "#sweeps-headline",
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: "ease.out" },
        "<"
      )
      .from(
        "#sweeps-copy",
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0
        },
        ">"
      )
      .from(
        "#sweeps-emailgate",
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0
        },
        ">"
      );
  }, {});

  return (
    <div className="lg:flex lg:justify-center lg:h-[314px] relative">
      <div className="relative pt-8 p-3 lg:p-6 w-full lg:w-2/3 lg:h-full flex-grow flex items-center justify-center">
        <div className="relative z-20 w-full max-w-[600px]">
          <div>
            <h2
              id="sweeps-headline"
              className="my-0 text-center text-[40px] leading-[50px] lg:text-[55px] lg:leading-[35px] text-[#0680B5] text-millik"
            >
              Last chance <span className="inline-block">to win!</span>
            </h2>
            <p
              id="sweeps-copy"
              className="text-[20px] lg:text-[25px] lg:leading-[30px] leading-tight py-4 max-w-[272px] lg:max-w-[472px] mx-auto text-dark-blue px-3"
            >
              Enter by 8/15/24 and you could sail to paradise on a Carnival cruise.
            </p>
          </div>
          <form id="sweeps-emailgate" onSubmit={handleSubmit(checkEmail)} className="px-4">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <div className="lg:flex">
              <Input
                id="email"
                type="email"
                placeholder="Email"
                aria-describedby="sweeps-headline"
                aria-required="true"
                aria-invalid={formErrors.email ? "true" : "false"}
                auto-complete="email"
                {...register("email", validation.email)}
                className={formErrors.email ? `border-red` : `border-[#0680B5]`}
              />
              <Button
                disabled={isSubmitting}
                as="submit"
                className="w-full my-2 lg:my-0 lg:max-w-[162px] px-0"
              >
                Continue
              </Button>
            </div>
            <div className="h-4">
              <InputError className="text-left">
                {formErrors.email && formErrors.email.message}
              </InputError>
            </div>
          </form>
        </div>
      </div>
      <div className="w-full lg:w-[435px] h-full flex-none overflow-hidden -mt-16 lg:mt-0">
        <div className="w-full h-full lg:hidden relative">
          <img
            src={`${awsUrl}/media/portals/one-year/IslandStill_sweeps-m.jpg`}
            srcSet={`${awsUrl}/media/portals/one-year/IslandStill_sweeps-m@2x.jpg 2x`}
            alt="Sweepstakes background"
            className="w-full h-full object-cover"
          />
          <div className="absolute w-full h-[23%] bg-gradient-to-b via-60% via-lighter-blue from-lightest-blue top-0 z-10"></div>
        </div>
        <img
          src={`${awsUrl}/media/portals/one-year/IslandStill_sweeps.jpg`}
          srcSet={`${awsUrl}/media/portals/one-year/IslandStill_sweeps@2x.jpg 2x`}
          alt="Sweepstakes background"
          className="w-full h-full object-cover hidden lg:block"
        />
      </div>
    </div>
  );
};

export default EmailForm;
