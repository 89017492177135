import React from "react";
import PropTypes from "prop-types";

const ModuleGalleryThumbnail = ({ setCurrentItemIndex, thumbnail, active, idx }) => {
  return (
    <div
      onClick={() => setCurrentItemIndex(idx)}
      className={`relative cursor-pointer w-[126px] h-[126px] mr-4 mb-4`}
    >
      <img src={thumbnail} alt={`thumbnail ${idx}`} className="w-full h-full" />
      <div
        className={`w-full h-full absolute top-0 hover:shadow-[inset_0px_0px_0px_8px_rgba(255,190,64,1)] transition-all duration-300 ${
          active ? "shadow-[inset_0px_0px_0px_8px_rgba(255,190,64,1)]" : ""
        }`}
      ></div>
    </div>
  );
};

ModuleGalleryThumbnail.propTypes = {
  setCurrentItemIndex: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  thumbnail: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired
};

export default ModuleGalleryThumbnail;
