/** @format */
import React from "react";
import { Link, NavLink } from "react-router-dom";

import { useUserContext } from "../../context/UserContext";
import { triggerGAEvent } from "../../helpers";
import Weather from "./Weather";

const Footer = () => {
  const { setShowIntro } = useUserContext();

  const anchorLinks = [
    {
      CTA: "Introducing&nbsp;Celebration&nbsp;Key<sup>&trade;</sup>",
      href: "/",
      hash: "#section-intro",
      gaEvent: "nav_f_intro"
    },
    {
      CTA: "Discover&nbsp;the&nbsp;Destination",
      href: "/",
      hash: "#section-discover",
      gaEvent: "nav_f_discover",
      borderClasses: "border-r border-r-[#A1CAFF] md:border-r-0 md:border-l md:border-l-[#A1CAFF]"
    },
    {
      CTA: "Featured&nbsp;Portal",
      href: "/",
      hash: "#featured-portal",
      gaEvent: "nav_f_featured-portal",
      borderClasses: "lg:border-r-0 lg:border-l lg:border-l-[#A1CAFF]"
    },
    {
      CTA: "Plan&nbsp;Your&nbsp;Day",
      href: "/",
      hash: "#section-your_day",
      gaEvent: "nav_f_plan",
      blockClass: "block",
      borderClasses: "border-r border-r-[#A1CAFF] lg:border-r-0 "
    },
    {
      CTA: "Book a Cruise",
      href: "/",
      hash: "#carnival-ships",
      gaEvent: "nav_f_book",
      borderClasses: "md:border-l md:border-l-[#A1CAFF] border-l-[#A1CAFF]"
    },
    {
      CTA: "Watch the Video",
      href: "/",
      hash: "#feature-video",
      gaEvent: "nav_f_video",
      borderClasses: "border-l lg:border-l lg:border-l-[#A1CAFF] border-l-[#A1CAFF]"
    }
  ];

  const pageLinks = [
    {
      CTA: "Legal Notices",
      href: "https://www.carnival.com/about-carnival/legal-notice.aspx",
      gaEvent: "nav_f_legal",
      ariaLabel: "Legal Notices (opens an external link)",
      external: true
    },
    {
      CTA: "Privacy & Cookies",
      href: "https://www.carnival.com/about-carnival/legal-notice/privacy-notice.aspx",
      gaEvent: "nav_f_privacy",
      external: true,
      ariaLabel: "Privacy & Cookies, opens an external link",
      borderClasses: "border-l border-l-[#A1CAFF]"
    },
    {
      CTA: "Official Rules",
      href: "/official-rules",
      gaEvent: "nav_f_rules",
      external: false,
      ariaLabel: "view full rules",
      borderClasses: "border-l md:border-l md:border-l-[#A1CAFF] border-l-[#A1CAFF]"
    }
  ];

  return (
    <footer className="bg-dark-blue text-white text-center pt-12 pb-6">
      <div className="container max-w-6xl mx-auto px-4">
        <Link
          to={"/"}
          className="inline-block"
          onClick={() => {
            setShowIntro(false);
            window.scroll(0, 0);
            triggerGAEvent("nav_f_logo");
          }}
        >
          <img
            className="w-[153px] mx-auto"
            src={`${process.env.REACT_APP_ASSET_URL}/media/logo-carnival-white.svg`}
            alt="Carnival"
          />
        </Link>
        <Weather />
        <nav
          aria-label="Home Sections"
          className="w-[350px] md:w-full max-w-[700px] mx-auto text-center my-6 text-[15px] md:text-[19px] leading-7"
        >
          <ul>
            {anchorLinks.map((link, idx) => (
              <li
                className={`${link.blockClass ? link.blockClass : ""} inline-block`}
                key={`LinkNav${idx}`}
              >
                <Link
                  tabIndex={0}
                  to={`${link.href}${link.hash}`}
                  className={`${
                    link.borderClasses ? link.borderClasses : ""
                  } px-2 inline hover:underline`}
                  onClick={() => {
                    setShowIntro(false);
                    triggerGAEvent(link.gaEvent);
                  }}
                  dangerouslySetInnerHTML={{ __html: link.CTA }}
                  aria-label={`${link.ariaLabel ? link.ariaLabel : ""}`}
                />
              </li>
            ))}
          </ul>
        </nav>
        <nav
          aria-label="Footer"
          className="w-[350px] md:w-full max-w-[575px] mx-auto text-center mt-6 mb-10 text-[15px] md:text-[19px] leading-7"
        >
          <ul className="my-2">
            {pageLinks.map((link, idx) => (
              <li className={`inline-block`} key={`LinkNav${idx}`}>
                {link.external ? (
                  <a
                    tabIndex={0}
                    href={link.href}
                    className={`${
                      link.borderClasses ? link.borderClasses : ""
                    } px-2 inline hover:underline`}
                    onClick={() => {
                      triggerGAEvent(link.gaEvent);
                    }}
                    aria-label={`${link.ariaLabel ? link.ariaLabel : ""}`}
                    dangerouslySetInnerHTML={{ __html: link.CTA }}
                  ></a>
                ) : (
                  <NavLink
                    tabIndex={0}
                    to={link.href}
                    className={({ isActive }) =>
                      `${
                        link.borderClasses ? link.borderClasses : ""
                      } px-2 inline hover:underline ${isActive && " underline"}`
                    }
                    onClick={() => {
                      setShowIntro(false);
                      triggerGAEvent(link.gaEvent);
                      window.scroll(0, 0);
                    }}
                    dangerouslySetInnerHTML={{ __html: link.CTA }}
                  />
                )}
              </li>
            ))}
          </ul>
        </nav>
        <p className="text-[14px] mx-auto">
          <strong>NO PURCHASE NECESSARY TO ENTER OR WIN. </strong> Sweepstakes begins at 12:00:00 PM
          ET on January 29, 2024 and ends at 11:59:59 pm ET on August 15, 2024 and will be divided
          into five (5) Entry Periods corresponding with one (1) Launch Period and four (4) Portal
          Unlocks. Open to legal residents of the fifty U.S., DC, Puerto Rico, and Canada (excluding
          Quebec) who are 21+. Void in Quebec and where prohibited.{" "}
          <a
            tabIndex={0}
            href="/official-rules"
            aria-label="click here to view full rules"
            className={`inline underline hover:underline`}
            onClick={() => {
              triggerGAEvent("nav_d_rules");
            }}
            dangerouslySetInnerHTML={{ __html: `Click here` }}
          ></a>{" "}
          for Official Rules, which govern, & complete details including Entry Period start/end
          dates/times. Sponsor: Carnival Cruise Line, a division of Carnival Corporation.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
