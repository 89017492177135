import React from "react";
import PropTypes from "prop-types";

const ModuleSideOneThird = ({ children, className }) => {
  return (
    <div
      className={`w-full h-1/2 lg:h-full lg:w-1/3 flex items-center justify-center ${
        className && className
      }`}
    >
      {children}
    </div>
  );
};

ModuleSideOneThird.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string
};

export default ModuleSideOneThird;
