/** @format */

import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import { awsUrl, triggerGAEvent } from "../helpers";

import ToggleSwitch from "../common/ToggleSwitch";

import { gsap } from "../gsap";
import { SplitText } from "../gsap/SplitText";
import { useGSAP } from "../gsap/react";

gsap.registerPlugin(SplitText, useGSAP);

function FeatureVideo() {
  const [activeFocus, setActiveFocus] = useState(false);

  const headlineTl = useRef();
  const mainVideo = useRef();
  const [audioChecked, setAudioChecked] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoSource, setVideoSource] = useState();
  const [durationGATriggered, setDurationGATriggered] = useState(false);

  const [playInline, setPlayInline] = useState(false);
  const [tabIndex, setTabIndex] = useState(-1);

  let isMobile =
    window.matchMedia("(max-width: 992px)").matches ||
    window.matchMedia("(pointer: coarse)").matches;

  useEffect(() => {
    isMobile ? setPlayInline(false) : setPlayInline(true);
  }, [isMobile, setPlayInline, playInline]);

  const playVideo = (e) => {
    setTabIndex(1);
    triggerGAEvent("home_video-play");
    setVideoPlaying(true);
  };

  const playFullScreen = (e) => {
    if (isMobile) {
      e.requestFullscreen();
    }
  };

  const pauseVideo = () => {
    setTabIndex(-1);
    triggerGAEvent("home_video-stop");
    setVideoPlaying(false);
  };

  const triggerVideo = (e) => {
    if (e.code === "Enter" || e.type === "click") {
      if (!videoPlaying) {
        playVideo();
      } else {
        pauseVideo();
      }
    }
  };

  const handleProgress = (e) => {
    if (e.played * 100 > 50) {
      if (!durationGATriggered) {
        triggerGAEvent("feature-video_50%_complete");
        setDurationGATriggered(true);
      }
    }
  };

  useEffect(() => {
    setVideoSource(
      audioChecked
        ? "https://player.vimeo.com/video/907311891?h=458b196a18&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479?playsinline=0"
        : "https://player.vimeo.com/video/899248467?h=29b60e466a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479?playsinline=0"
    );
  }, [audioChecked]);

  const handleFullScreen = () => {
    if (document.fullscreenElement !== null) {
      setActiveFocus(true);

      const featureVideo = document.querySelector("#feature-video");
      if (featureVideo) {
        featureVideo.focus();
      }
    } else {
      setActiveFocus(false);
    }
  };

  useEffect(() => {
    //we need to force the focus exiting fullSCreen state

    document.addEventListener("fullscreenchange", handleFullScreen);

    document.addEventListener("webkitfullscreenchange", handleFullScreen);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreen);
      document.removeEventListener("webkitfullscreenchange", handleFullScreen);
    };
  }, [activeFocus, setActiveFocus]);

  useGSAP(() => {
    let titleRef = new SplitText("#featureHeadline", { type: "chars, words" });
    let captionRef = new SplitText("#featureCaption", { type: "lines" });

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#feature-video"
          // markers: true
        }
      })
      .from(
        captionRef.lines,
        {
          duration: 0.65,
          y: 20,
          autoAlpha: 0,
          stagger: 0.03
        },
        "<"
      )
      .from(
        titleRef.chars,
        {
          duration: 0.65,
          x: 10,
          autoAlpha: 0,
          stagger: 0.05
        },
        ">"
      );
  }, {});

  return (
    <section
      id="feature-video"
      className={`z-30 relative h-screen lg:h-auto bg-dark-blue overflow-hidden`}
      data-tag="section_7_video"
      data-pixel="carni045+unique"
    >
      <div
        className={`absolute top-0 left-0 lg:top-[40px] w-full h-full flex flex-col mt-[38px] lg:mt-[38px] pb-24 z-10 ${
          videoPlaying ? "hidden" : "block"
        }`}
      >
        <span
          id="featureCaption"
          className="block text-[19px]  leading-[25px] lg:text-[25px] lg:leading-[25px] mb-6 px-6 lg:px-0 text-white text-shadow"
        >
          White sand beaches. Delicious dining.{" "}
          <span className="block lg:inline">Space to unwind.</span>
        </span>
        <h2
          id="featureHeadline"
          className="text-white text-millik text-[32px] leading-[34px] lg:text-[44px] lg:leading-[44px] text-center text-shadow px-3 font-normal lg:px-3"
          aria-hidden="true"
          aria-label="There’s a lot more to see at Celebration Key™"
        >
          There’s a lot more to see at Celebration Key&nbsp;
          <sup className="text-[14px] -top-[10px] -ml-[6px] lg:-ml-[8px] lg:text-[16px] lg:-top-[12px]">
            &trade;
          </sup>
        </h2>
      </div>
      <div
        className={`absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center pb-24 z-10 ${
          videoPlaying ? "hidden" : "block"
        }`}
      >
        <span
          role="button"
          tabIndex={0}
          ariaLabel="Play featured video"
          onClick={triggerVideo}
          onKeyDown={triggerVideo}
          className="material-symbols-rounded filled text-[138px] hover:opacity-50 cursor-pointer text-white"
          autoFocus
        >
          play_arrow
        </span>
      </div>
      <div
        className={
          "absolute left-[2%] bottom-[15%] z-20 cursor-pointer grid grid-cols-2 items-center"
        }
      >
        <img
          className="w-[60px]"
          src={awsUrl + `/media/icon-AD.png`}
          alt="audio description icon"
        />
        <ToggleSwitch
          checked={audioChecked}
          onChange={setAudioChecked}
          ariaLabel={"Turn audio description " + (audioChecked ? "off" : "on")}
        />
      </div>
      <div
        className={
          "relative w-full top-0 left-0 cursor-pointer  " + (videoPlaying ? "z-10" : "z-0 ")
        }
      >
        <div className="aspect-video">
          <img
            className={
              `absolute top-0 lg:block w-full ` +
              (videoPlaying ? "opacity-0 pointer-events-none" : "z-30 ")
            }
            src={awsUrl + `/media/feature-poster.jpg`}
            alt=""
          />
          <img
            className={
              `absolute top-0 block lg:hidden w-full ` + (videoPlaying ? "hidden" : "z-30 ")
            }
            src={awsUrl + `/media/feature-poster-m.jpg`}
            alt=""
          />
          <div className={`absolute w-full h-full`} tabIndex={tabIndex}>
            {" "}
            <ReactPlayer
              ref={mainVideo}
              className="pointer-events-auto react-player"
              volume={1}
              playing={videoPlaying}
              controls={true}
              url={videoSource}
              width="100%"
              height="100%"
              onPlay={playVideo}
              onPause={pauseVideo}
              playsinline={playInline}
              onEnded={() => triggerGAEvent("feature-video_complete")}
              onProgress={handleProgress}
              onStart={() => playFullScreen()}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureVideo;
