import React, { useState } from "react";
import "./contact.css";

import { awsUrl, triggerGAEvent } from "../../helpers";
import { Link } from "react-router-dom";

import { ContactForm, ThankYou } from "../../components/ContactUs";
import Footer from "../../components/Footer";
import { useUserContext } from "../../context/UserContext";

function ContactUs() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { setCurrentPage } = useUserContext();
  setCurrentPage("contact-us");

  return (
    <div className="w-full bg-lightest-blue min-h-screen flex flex-col">
      <div className="flex-grow">
        <div className="bg-[#A1CAFF] flex justify-center py-4">
          <Link
            to={"/"}
            className="block"
            onClick={() => {
              triggerGAEvent("nav_logo");
              window.scroll(0, 0);
            }}
          >
            <img
              src={awsUrl + "/media/logo-celebration-key_carnival_final.png?v=1"}
              srcSet={awsUrl + "/media/logo-celebration-key_carnival_final@2x.png?v=1 2x"}
              alt="Celebration Key logo"
              className="w-[250px]"
            />
          </Link>
        </div>
        <section id="contact-us" className="container mx-auto text-dark-blue px-4 py-20 max-w-3xl">
          <h1 className="text-center text-tempo uppercase text-5xl font-bold mb-10">Contact Us</h1>
          {!formSubmitted && (
            <ContactForm
              onComplete={() => {
                setFormSubmitted(true);
                window.scrollTo(0, 0);
              }}
            />
          )}
          {formSubmitted && <ThankYou />}
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
