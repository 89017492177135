import React, { forwardRef } from "react";

const Textarea = forwardRef((props, ref) => {
  let classes = [
    "appearance-none",
    "relative",
    "block",
    "w-full",
    "text-[15px]",
    "border",
    "px-3",
    "h-40",
    "ring-0",
    "bg-white",
    "text-black",
    "placeholder-gray-dark",
    "focus:border-blue",
    "focus:ring-2",
    "focus:ring-light-blue",
    "active:border-blue",
    "active:right-0",
    "resize-none"
  ];

  if (props.className) {
    classes.push(props.className);
  }

  if (props.error) {
    classes.push("border-red");
  } else {
    classes.push("border-blue");
  }

  return (
    <textarea
      {...props}
      ref={ref}
      aria-invalid={props.error ? "true" : "false"}
      className={classes.join(" ")}
    />
  );
});

export default Textarea;
