import React, { useState } from "react";

import {
  AccordionGallery,
  FlyThrough,
  Gallery,
  InteractiveMap,
  SideBySide,
  SlideShow
} from "../../components/UnlockedPortal/Modules";
import Button from "../../common/Elements/Button";
import Video from "../../common/Video";
import {
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleSideHalf,
  ModuleSideOneThird,
  ModuleSideTwoThirds
} from "../../components/UnlockedPortal/Modules/Parts";

const Example = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  const moduleA = () => {
    return (
      <FlyThrough
        videoName="portals/flythrough"
        isPlaying={isPlaying}
        headline="Module Flyover"
        bodyCopy="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa doloribus error expedita minima minus odio quae quia tenetur. Animi atque dicta eveniet facere harum iste molestiae nulla recusandae soluta voluptatibus."
      ></FlyThrough>
    );
  };

  const moduleB = () => {
    const slides = [
      {
        video: "portals/flythrough",
        headline: "Module Slider 1",
        bodyCopy:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa doloribus error expedita minima minus odio quae quia tenetur. Animi atque dicta eveniet facere harum iste molestiae nulla recusandae soluta voluptatibus."
      },
      {
        video: "portals/flythrough",
        headline: "Module Slider 2",
        bodyCopy:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi eum fugiat magni nisi officiis perferendis soluta voluptate! Asperiores cum dicta eligendi fugiat, fugit itaque libero magni, nemo porro sit voluptatum."
      },
      {
        video: "portals/flythrough",
        headline: "Module Slider 3",
        bodyCopy:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad atque dignissimos doloribus, facere hic impedit, iure maxime minus molestias nam nulla odio odit perspiciatis porro, possimus provident quod recusandae vero?"
      }
    ];

    return <SlideShow slides={slides} isPlaying={isPlaying} />;
  };

  const moduleC = () => {
    const galleryItems = [
      {
        video: "portals/welcome/dancing",
        poster: "portals/welcome/dancing",
        thumbnail: "https://placehold.co/126x126/af324d/ffffff"
      },
      {
        video: "portals/flythrough",
        poster: "portals/flythrough",
        thumbnail: "https://placehold.co/126x126/953af4/000000"
      },
      {
        video: "portals/welcome/views",
        poster: "portals/welcome/views",
        thumbnail: "https://placehold.co/126x126/765934/ffffff"
      }
    ];
    return (
      <Gallery
        galleryItems={galleryItems}
        isPlaying={isPlaying}
        headline="Gallery Module"
        bodyCopy="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa doloribus error expedita minima minus odio quae quia tenetur. Animi atque dicta eveniet facere harum iste molestiae nulla recusandae soluta voluptatibus."
      />
    );
  };

  const moduleD = () => {
    return <AccordionGallery />;
  };

  const moduleE = () => {
    const mapData = [
      {
        title: "Map Spot One",
        copy: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
        location: {
          x: "47%",
          y: "46%",
          xMob: "47%",
          yMob: "47%"
        },
        image: "https://placehold.co/425x225/af324d/ffffff"
      },
      {
        title: "Map Spot One",
        copy: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
        location: {
          x: "27%",
          y: "76%",
          xMob: "47%",
          yMob: "47%"
        },
        image: "https://placehold.co/425x225/de4356/ffffff"
      }
    ];

    return (
      <InteractiveMap
        mapImage="/static/assets/bg-island.jpg"
        headline="Interactive Map Module"
        dataPoints={mapData}
      />
    );
  };

  const moduleF = () => {
    return (
      <SideBySide>
        <ModuleSideHalf className="bg-dark-blue">
          <div className="w-full h-full relative overflow-hidden">
            <Video
              poster={"portals/flythrough"}
              name={"portals/flythrough"}
              autoplay={isPlaying}
            ></Video>
          </div>
        </ModuleSideHalf>
        <ModuleSideHalf className="bg-gradient-to-tr from-dark-blue to-lightest-blue">
          <div className="px-4 md:px-10 text-center">
            <ModuleCopyHeadline theme={"light"}>Module Side By Side Halves</ModuleCopyHeadline>
            <ModuleCopyBody theme={"light"}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur deserunt
              dolorem earum inventore laborum mollitia nisi, optio reprehenderit veritatis!
              Architecto aut earum minus, mollitia nisi officia quas quo veritatis!
            </ModuleCopyBody>
          </div>
        </ModuleSideHalf>
      </SideBySide>
    );
  };

  const moduleG = () => {
    return (
      <SideBySide>
        <ModuleSideOneThird className="bg-dark-blue">
          <div className="px-4 md:px-10 text-center">
            <ModuleCopyHeadline theme={"light"}>Module Side By Side Thirds</ModuleCopyHeadline>
            <ModuleCopyBody theme={"light"}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur deserunt
              dolorem earum inventore laborum mollitia nisi, optio reprehenderit veritatis!
              Architecto aut earum minus, mollitia nisi officia quas quo veritatis!
            </ModuleCopyBody>
          </div>
        </ModuleSideOneThird>
        <ModuleSideTwoThirds>
          <div className="w-full h-full relative overflow-hidden">
            <Video
              poster={"portals/flythrough"}
              name={"portals/flythrough"}
              autoplay={isPlaying}
            ></Video>
          </div>
        </ModuleSideTwoThirds>
      </SideBySide>
    );
  };

  return (
    <div>
      <Button onClick={() => setIsPlaying(!isPlaying)}>Toggle Play</Button>
      <div className="flex flex-col items-center justify-center">
        <div className="text-white text-4xl">Module A</div>
        {moduleA()}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-white text-4xl">Module B</div>
        {moduleB()}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-white text-4xl">Module C</div>
        {moduleC()}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-white text-4xl">Module D</div>
        {moduleD()}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-white text-4xl">Module E</div>
        {moduleE()}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-white text-4xl">Module F</div>
        {moduleF()}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-white text-4xl">Module G</div>
        {moduleG()}
      </div>
    </div>
  );
};

export default Example;
