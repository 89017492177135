import { useEffect, useState } from "react";
import { triggerGAEvent } from "../helpers";

export const useScrollDetectors = (page, showPreloader = false) => {
  const [windowHeight, setWindowHeight] = useState();
  const [docHeight, setDocHeight] = useState();
  const [trackLength, setTrackLength] = useState();

  useEffect(() => {
    const getDocHeight = () => {
      var D = document;
      return Math.max(
        D.body.scrollHeight,
        D.documentElement.scrollHeight,
        D.body.offsetHeight,
        D.documentElement.offsetHeight,
        D.body.clientHeight,
        D.documentElement.clientHeight
      );
    };

    const getMeasurements = () => {
      setWindowHeight(window.innerHeight);
      setDocHeight(getDocHeight());
      setTrackLength(docHeight - windowHeight);
    };

    let scroll = {
      quarter: false,
      half: false,
      threeQuarters: false,
      full: false
    };

    const amountScrolled = () => {
      let scrollTop = window.scrollY;
      let userScrolled = Math.floor((scrollTop / trackLength) * 100);

      const pre = page === "home" ? "" : page + "_";

      if (!scroll.quarter && userScrolled > 25) {
        scroll.quarter = true;
        triggerGAEvent(pre + "page-scroll-25");
      }

      if (!scroll.half && userScrolled > 50) {
        scroll.half = true;
        triggerGAEvent(pre + "page-scroll-50");
      }

      if (!scroll.threeQuarters && userScrolled > 75) {
        scroll.threeQuarters = true;
        triggerGAEvent(pre + "page-scroll-75");
      }

      if (!scroll.full && userScrolled > 98) {
        scroll.full = true;
        triggerGAEvent(pre + "page-scroll-100");
      }
    };

    if (!showPreloader) {
      getMeasurements();
      window.addEventListener("scroll", amountScrolled);
    }
    return () => {
      window.removeEventListener("scroll", amountScrolled, false);
    };
  }, [showPreloader, windowHeight, docHeight, trackLength, page]);
};
