import React from "react";
import PropTypes from "prop-types";

const ModuleCopyBlock = ({
  children,
  className,
  width = "full",
  padding,
  gradient = true,
  tallGradient = false
}) => {
  const widthClasses = {
    "1/3": "w-1/3",
    "5/12": "w-5/12",
    "1/2": "w-1/2",
    "2/3": "w-2/3",
    "3/4": "w-3/4",
    full: "w-full mx-auto text-center"
  };

  return (
    <div className={`absolute z-10 top-0 h-full w-full flex flex-col justify-end ` + className}>
      <div className="pb-16 px-[24px] lg:px-10 pointer-events-auto ">
        <div className={`max-w-4xl lg:px-4  ${widthClasses[width]} ${padding}`}>{children}</div>
      </div>
      {gradient ? (
        <div
          className={
            tallGradient
              ? `absolute w-full  lg:h-[50%] bg-gradient-to-t from-50% from-dark-blue -z-[1] h-[50%]`
              : `absolute w-full  lg:h-[50%] bg-gradient-to-t from-dark-blue -z-[1] h-[70%]`
          }
        ></div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

ModuleCopyBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  width: PropTypes.string
};

export default ModuleCopyBlock;
