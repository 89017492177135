import { useEffect } from "react";

export const useEscape = (callback) => {
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", escFunction, false);

    return () => {
      window.removeEventListener("keydown", escFunction, false);
    };
  });
};
