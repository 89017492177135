import React from "react";

const Label = ({ children, htmlFor, className }) => {
  return (
    <label
      className={`${className} text-[15px] text-dark-blue mb-1`}
      aria-label={children}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};

export default Label;
