import React, { forwardRef, useEffect, useState } from "react";

const Select = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    let classesArray = [
      "appearance-none",
      "relative",
      "block",
      "w-full",
      "text-[15px]",
      "border",
      "px-3",
      "h-12",
      "ring-0",
      "bg-white",
      "placeholder-gray-dark",
      "focus:border-blue",
      "focus:ring-2",
      "focus:ring-light-blue",
      "active:border-blue",
      "active:right-0"
    ];

    if (props.className) {
      classesArray.push(props.className);
    }

    if (props.error) {
      classesArray.push("border-red");
    } else {
      classesArray.push("border-[#0680B5]");
    }

    if (value) {
      classesArray.push("text-black");
    } else {
      classesArray.push("text-gray-600");
    }

    setClasses(classesArray);
  }, [value, props.className, props.error]);

  return (
    <select
      {...props}
      onChange={(e) => {
        props.onChange(e);
        setValue(e.target.value);
      }}
      ref={ref}
      aria-invalid={props.error ? "true" : "false"}
      className={classes.join(" ")}
    >
      {props.options.map((option, idx) => (
        <option value={option.value} key={`Option-${idx}`}>
          {option.label}
        </option>
      ))}
    </select>
  );
});

export default Select;
