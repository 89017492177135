import React, { forwardRef } from "react";
import Label from "./Label";
import Textarea from "./Textarea";
import InputError from "./InputError";

const FormTextareaGroup = forwardRef((props, ref) => {
  const errorId = props.id + "-error";

  return (
    <div className={props.className}>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Textarea {...props} error={props.error} ref={ref} aria-describedby={errorId} />
      <InputError id={errorId} className="text-left">
        {props.error && props.error.message}
      </InputError>
    </div>
  );
});

export default FormTextareaGroup;
