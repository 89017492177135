import React from "react";
import "./App.css";

import ReactDOM from "react-dom/client";
import { UserProvider } from "./context/UserContext";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./routes/Home";
import ErrorPage from "./routes/ErrorPage";
import ContactUs from "./routes/Contact";
import Rules from "./routes/Rules";
import {
  AdultFriendly,
  Example,
  FamilyFriendly,
  PrivatePortal,
  Retail,
  Welcome
} from "./routes/Portals";

const portals = {
  example: Example,
  "paradise-plaza": Welcome,
  "calypso-lagoon": AdultFriendly,
  "lokono-cove": Retail,
  "starfish-lagoon": FamilyFriendly,
  "pearl-cove": PrivatePortal
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: "contact-us",
    element: <ContactUs />
  },
  {
    path: "official-rules",
    element: <Rules />
  },
  ...Object.keys(portals).map((path) => ({
    path: "portals/" + path,
    element: React.createElement(portals[path])
  }))
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  </React.StrictMode>
);
