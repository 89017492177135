/** @format */

import React, { useEffect } from "react";

import { awsUrl } from "../helpers";

const Video = ({ name, autoplay = false, videoplaying = true }) => {
  useEffect(() => {}, [videoplaying]);

  try {
    let isMobile =
      window.matchMedia("(max-width: 992px)").matches ||
      window.matchMedia("(pointer: coarse)").matches;

    // Import image on demand
    const video = !isMobile
      ? `${awsUrl}/media/${name}.mp4?v=4`
      : `${awsUrl}/media/${name}-m.mp4?v=2`;

    const poster = !isMobile
      ? `${awsUrl}/media/${name}-poster.jpg`
      : `${awsUrl}/media/${name}-m-poster.jpg?v=1`;

    if (!video) return null;

    if (autoplay && videoplaying) {
      return (
        <div
          className="h-full"
          dangerouslySetInnerHTML={{
            __html: `
				<video
					width="100%"
					height="100%"
					autoplay loop muted playsinline
					preload="auto"
					poster=${poster}

				>
					<source src=${video + "#t=0.1"} type="video/mp4" />


					Your browser does not support the video tag.
				</video>
				 `
          }}
        ></div>
      );
    } else {
      return (
        <div
          className="h-full"
          dangerouslySetInnerHTML={{
            __html: `
              <video
                  width="100%"
                  height="100%"

                  preload="meta"
                  poster=${poster}

              >
                  <source src=${video + "#t=0.1"} type="video/mp4" />


                  Your browser does not support the video tag.
              </video>
               `
          }}
        ></div>
      );
    }
  } catch (error) {
    console.log(error);
    console.log(`Video with name "${name}" does not exist`);

    return null;
  }
};

export default Video;
