import { useEffect, useState } from "react";

const iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

export const useZoomDetector = () => {
  const [isZoom, setIsZoom] = useState(false);
  const [zoomWidth, setZoomWidth] = useState();
  const handleZoomChange = () => {
    if (iOS) {
      window.visualViewport.scale > 1 ? setIsZoom(true) : setIsZoom(false);
      setZoomWidth("m-zoom200");
    } else {
      let zoomW = ((window.outerWidth - 10) / window.innerWidth) * 100;
      zoomW > 195 ? setIsZoom(true) : setIsZoom(false);

      let zoomClass;

      if (Math.round(zoomW) > 395) {
        zoomClass = "zoom400 zoom-extra";
      } else if (Math.round(zoomW) > 297) {
        zoomClass = "zoom400";
      } else {
        zoomClass = "zoom200";
      }
      setZoomWidth(zoomClass);
    }
  };

  useEffect(() => {
    handleZoomChange();
    window.addEventListener("resize", handleZoomChange, false);

    return () => {
      window.removeEventListener("resize", handleZoomChange);
    };
  }, [isZoom, setIsZoom, setZoomWidth]);

  const zoomStyles = isZoom ? `isZoom w-${zoomWidth}` : ``;

  return { isZoom, zoomStyles };
};
