import React, { useRef } from "react";
import PropTypes from "prop-types";
import { ModuleCopyBlock, ModuleCopyBody, ModuleCopyHeadline, ModuleSection } from "./Parts";
import { useUserContext } from "../../../context/UserContext";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import Video from "../../../common/PortalVideo";

import { useGSAP } from "../../../gsap/react";
import { gsap } from "../../../gsap";
import { SplitText } from "../../../gsap/SplitText";

gsap.registerPlugin(SplitText, useGSAP);

const SlideShow = ({ slides, autoplaySpeed, id, padding, dontBuild = false }) => {
  const swiperRef = useRef();
  const headlineTl = useRef();

  const { allVideosPlaying } = useUserContext();

  const restartVideos = (swiper) => {
    console.log("fire");
    let curVideo = swiper.slides[swiper.activeIndex].querySelector("video");
    curVideo.playsInline = true;
    curVideo.muted = true;
    curVideo.currentTime = 0;
    curVideo.controlsList = "noplaybackrate nodownload";

    if (allVideosPlaying) {
      curVideo && curVideo.play();
    }
  };

  useGSAP(() => {
    if (!dontBuild) {
      let titleRef = new SplitText("#slide-headline0", { type: "chars, words" });
      let captionRef = new SplitText("#slide-copy0", { type: "lines" });

      headlineTl.current = gsap
        .timeline({
          scrollTrigger: {
            start: "top center",
            trigger: ".unlocked-swiper"
            // markers: true
          }
        })
        .from(
          titleRef.chars,
          {
            duration: 0.65,
            x: 10,
            autoAlpha: 0,
            stagger: 0.05
          },
          "0"
        )
        .from(
          captionRef.lines,
          {
            duration: 0.65,
            y: 20,
            autoAlpha: 0,
            stagger: 0.03
          },
          ">-=.5"
        );
    }
  }, {});

  const pagination = {
    el: ".center-pagination",
    clickable: true,
    type: "bullets",
    bulletClass: "swiper-custom-bullet",
    bulletActiveClass: "swiper-custom-bullet-active",
    renderBullet: function (index, className) {
      return `<span role="button" class="swiper-custom-bullet z-[9999]" aria-label="view slide ${index}"></span>`;
    }
  };

  return (
    <ModuleSection className="relative w-full unlocked-swiper">
      <Swiper
        id={id}
        effect={"fade"}
        ref={swiperRef}
        loop="true"
        spaceBetween={50}
        slidesPerView={1}
        pagination={pagination}
        modules={[Autoplay, EffectFade, Pagination, Navigation]}
        className={`h-full`}
        onRealIndexChange={(swiper) => {
          restartVideos(swiper);
        }}
        autoplay={{
          enabled: true,
          delay: autoplaySpeed,
          disableOnInteraction: false
        }}
      >
        {slides.map((slide, index) => {
          return (
            <SwiperSlide key={`${index}-Slide`}>
              <Video
                poster={slide.video}
                name={slide.video}
                autoplay={allVideosPlaying}
                videoplaying={allVideosPlaying}
              />
              <div>
                <ModuleCopyBlock padding={"lg:pb-[4rem]"}>
                  <ModuleCopyHeadline id={`slide-headline` + index} theme={`light`}>
                    {slide.headline}
                  </ModuleCopyHeadline>
                  <ModuleCopyBody id={`slide-copy` + index} theme={`light`}>
                    <span dangerouslySetInnerHTML={{ __html: slide.bodyCopy }}></span>
                  </ModuleCopyBody>
                </ModuleCopyBlock>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="custom-pagination center-pagination z-20 absolute left-[50%] -translate-x-1/2 w-full bottom-8 "></div>
    </ModuleSection>
  );
};

SlideShow.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      video: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      bodyCopy: PropTypes.string.isRequired
    })
  ).isRequired,
  isPlaying: PropTypes.bool.isRequired
};

export default SlideShow;
