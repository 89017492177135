import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../../common/ScrollToTop";
import UnlockedPortalNav from "./UnlockedPortalNav";
import Button from "../../../common/Elements/Button";
import PropTypes from "prop-types";
import { triggerGAEvent } from "../../../helpers";
import Footer from "../../Footer";
import { useZoomDetector } from "../../../hooks/useZoomDetector";
import { useUserContext } from "../../../context/UserContext";

const UnlockedPortalWrapper = ({ children, bgColorClass, gaPrefix }) => {
  const { zoomStyles } = useZoomDetector();
  const { isPromoOver } = useUserContext();

  return (
    <div className={bgColorClass + ` ` + zoomStyles}>
      <ScrollToTop />
      <UnlockedPortalNav bgColorClass={bgColorClass} gaPrefix={gaPrefix} />
      <main>
        {children}
        {!isPromoOver && (
          <div className=" bg-[#E3F2FA] px-[22px] py-14 lg:py-0 lg:h-[206px] flex items-center justify-center">
            <div className="text-center text-[#10559A] leading-tight pt-[20px] md:pt-0">
              <h2 className="text-[30px] md:text-[35px] text-millik">Last Chance to Win</h2>
              <p className="text-[24px] md:text-[31px] leading-[35px] mb-5 ">
                You could sail to paradise on a Carnival cruise.
              </p>
              <Link
                to={"/#sweeps"}
                className="font-semibold"
                onClick={() => {
                  triggerGAEvent(`${gaPrefix}_sweepstakes`);
                }}
              >
                <Button theme="light" color={`bg-[#10559A]`} className={` px-20 lg:px-24 `}>
                  Enter now
                </Button>
              </Link>
            </div>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

UnlockedPortalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  bgColorClass: PropTypes.string.isRequired,
  gaPrefix: PropTypes.string.isRequired
};

export default UnlockedPortalWrapper;
