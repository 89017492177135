import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Video from "../../../common/Video";
import { ReactCompareSlider } from "react-compare-slider";
import { ModuleCopyBlock, ModuleSection } from "./Parts";
import { useUserContext } from "../../../context/UserContext";

const CompareSlider = ({ portal, children, itemOne, itemTwo }) => {
  // Override Aria issues with React Compare Slider
  useEffect(() => {
    const button = document.querySelector('button[data-rcs="handle-container"]');
    button.setAttribute("aria-label", "Use arrow keys to move");
    button.setAttribute("role", "button");
    button.setAttribute("draggable", true);
    button.removeAttribute("aria-orientation");
    button.removeAttribute("aria-valuemin");
    button.removeAttribute("aria-valuemax");
    button.removeAttribute("aria-valuenow");

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes" && mutation.attributeName === "aria-valuenow") {
          mutation.target.removeAttribute("aria-valuenow");
        }
      }
    });
    observer.observe(button, { attributes: true });

    button.querySelector("div.__rcs-handle-root").removeAttribute("aria-label");

    return () => {
      observer.disconnect();
    };
  }, []);

  const { allVideosPlaying } = useUserContext();

  return (
    <ModuleSection>
      <div className={`${portal} unlocked-compare-slider w-full`}>
        {" "}
        <ReactCompareSlider
          position={75}
          style={{
            height: "100vh",
            width: "100%"
          }}
          onlyHandleDraggable={true}
          portrait={false}
          itemOne={
            <Video
              videoHeight={`w-full`}
              poster={itemOne}
              name={itemOne}
              autoplay={allVideosPlaying}
              videoplaying={allVideosPlaying}
            ></Video>
          }
          itemTwo={
            <Video
              poster={itemTwo}
              name={itemTwo}
              autoplay={allVideosPlaying}
              videoplaying={allVideosPlaying}
            ></Video>
          }
        />
      </div>

      <ModuleCopyBlock className={`pointer-events-none`}>{children}</ModuleCopyBlock>
    </ModuleSection>
  );
};

CompareSlider.propTypes = {
  isPlaying: PropTypes.bool.isRequired
};

export default CompareSlider;
