import React from "react";
import { ModuleCopyHeadline, ModuleSection } from "./Parts";
import PropTypes from "prop-types";

const InteractiveMap = ({ headline, dataPoints, mapImage }) => {
  return (
    <ModuleSection>
      <div className="relative w-full h-full">
        <img src={mapImage} alt="" />
        <div className="absolute w-full text-center mt-6">
          <ModuleCopyHeadline theme={"light"}>{headline}</ModuleCopyHeadline>
        </div>
        <div className="h-full w-full overflow-x-scroll md:overflow-auto">
          <div className="h-full w-full">
            {dataPoints.map((point, idx) => {
              return (
                <div
                  key={idx}
                  className="w-[20px] h-[20px] bg-white absolute"
                  style={{ top: point.location.y, left: point.location.x }}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    </ModuleSection>
  );
};

InteractiveMap.propTypes = {
  mapImage: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  dataPoints: PropTypes.array.isRequired
};

export default InteractiveMap;
