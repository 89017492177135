import React, { useRef } from "react";
import Button from "../../common/Elements/Button";

import QuizSlider from "./QuizSlider";

import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";
import { useGSAP } from "../../gsap/react";
import { awsUrl, scrollTo, triggerGAEvent } from "../../helpers";

gsap.registerPlugin(SplitText, useGSAP);

const QuizQuestions = ({ questions, setAnswer, submit }) => {
  const headlineTl = useRef();

  useGSAP(() => {
    let titleRef = new SplitText("#planHeadline", { type: "chars, words" });
    let captionRef = new SplitText("#planCaption", { type: "lines" });

    const iconSwipe = document.getElementById("ico-swipe");

    const splitRevert = () => {
      captionRef.revert();
    };

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top 70%",
          trigger: "#planHeadline"
          // markers: true
        }
      })
      .from(
        titleRef.chars,
        {
          duration: 0.65,
          x: 10,
          autoAlpha: 0,
          stagger: 0.05
        },
        "<"
      )
      .from(
        captionRef.lines,
        {
          duration: 0.45,
          y: 20,
          autoAlpha: 0,
          stagger: 0.05,
          onComplete: splitRevert
        },
        ">-=1.5"
      )
      .from(".slidecontainer", { y: 10, opacity: 0, duration: 1 }, ">")
      .from(iconSwipe, { y: 20, opacity: 0 }, "<+=1")
      .to(iconSwipe, { x: -30 }, "<+=.5")
      .to(iconSwipe, { x: 30 }, ">")
      .to(iconSwipe, { x: 0 }, ">")
      .to(iconSwipe, { y: 20, opacity: 0 }, "<+=.5")
      .to(iconSwipe, { display: "none" }, ">");
  }, {});

  return (
    <div>
      <h2
        id="planHeadline"
        className="text-[26px] max-w-[225px] leading-[32px] lg:text-[45px] md:leading-[50px] text-millik text-bg-dark-blue sm:max-w-[700px] mx-auto mt-[48px] mb-2"
        aria-label="Create your day in paradise"
      >
        <span aria-hidden="true">Create your day in paradise</span>
      </h2>
      <p
        id="planCaption"
        className="px-10 lg:px-0 text-[19px] lg:text-[19px] lg:mt-[18px] text-bg-dark-blue text-center"
        aria-hidden="true"
      >
        <p className="sr-only">
          {" "}
          Move the sliders below to plan the fun, food, and activities that are your&nbsp; key
          to&nbsp;paradise.
        </p>
        Move the sliders below to plan the&nbsp;fun, food, and activities that are your&nbsp;key
        to&nbsp;paradise.
      </p>

      <div className="slidecontainer flex items-center flex-col mx-auto  text-center w-full relative">
        <div className="w-full my-8">
          <div className="bg-white/80 py-8 lg:pb-1 lg:pt-2 mb-8 px-4 md:px-3">
            {questions.map((question, idx) => {
              return (
                <QuizSlider
                  question={question}
                  idx={idx}
                  setAnswer={setAnswer}
                  key={`Slider_${idx}`}
                />
              );
            })}
          </div>
          <Button
            type="submit"
            onClick={() => {
              scrollTo("section-your_day");
              triggerGAEvent("unlock-my-paradise");
              submit();
            }}
            className="px-8"
          >
            Unlock my paradise
          </Button>
        </div>
        <img
          id="ico-swipe"
          className="absolute top-[160px]"
          src={`${awsUrl}/media/ico-swipe.svg`}
          alt="icon of finger swiping left and right"
        />
      </div>
    </div>
  );
};

export default QuizQuestions;
