import axios from "axios";

const endpoints = {
  checkEmail: "/api/email",
  register: "/api/register",
  quiz: "/api/quiz",
  weather: "/api/weather",
  contactUs: "/api/contact-us"
};

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const postEmail = async (data) => {
  return apiClient.post(endpoints.checkEmail, data);
};

const postRegistration = async (data) => {
  return apiClient.post(endpoints.register, data);
};

const postQuizData = async (data) => {
  return apiClient.post(endpoints.quiz, data);
};

const getContactFormCaptcha = async () => {
  const response = await apiClient.get(endpoints.contactUs);
  return response.data.captcha;
};

const postContactForm = async (data) => {
  return apiClient.post(endpoints.contactUs, data);
};

const getWeatherData = async () => {
  const response = await apiClient.get(endpoints.weather);
  return response.data;
};

export {
  postEmail,
  postRegistration,
  postQuizData,
  getContactFormCaptcha,
  postContactForm,
  getWeatherData
};
