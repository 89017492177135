import React, { forwardRef } from "react";

const Input = forwardRef((props, ref) => {
  let classes = [
    "appearance-none",
    "relative",
    "block",
    "w-full",
    "text-[19px] lg:text-[16px]",
    "border",
    "px-3",
    "h-12",
    "",
    "ring-0",
    "bg-white",
    "text-dark-blue",
    "placeholder-gray-dark",
    "focus:border-blue",
    "focus:ring-2",
    "focus:ring-light-blue",
    "active:border-[#0680B5]",
    "active:right-0"
  ];

  if (props.className) {
    classes.push(props.className);
  }

  if (props.error) {
    classes.push("border-1 border-red");
  } else {
    classes.push("border-[#0680B5]");
  }

  return (
    <input
      {...props}
      ref={ref}
      aria-invalid={props.error ? "true" : "false"}
      className={classes.join(" ")}
    />
  );
});

export default Input;
