import React from "react";
import PropTypes from "prop-types";

const ModuleCopyBody = ({ children, theme, align = "center", id, classType, disclaimer }) => {
  const textColor = theme === "light" ? "text-white" : "text-dark-blue";

  return (
    <div
      id={id}
      className={`w-full text-[19px] leading-[25px] md:text-[19px] md:leading-[25px] ${textColor} text-${align} ${classType}`}
    >
      {children}
      {disclaimer && <small className="block mt-3">{disclaimer}</small>}
    </div>
  );
};

ModuleCopyBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  theme: PropTypes.oneOf(["light", "dark"]).isRequired
};

export default ModuleCopyBody;
