import React, { useState } from "react";
import { postQuizData } from "../../services/apiClient";
import { useUserContext } from "../../context/UserContext";
import { awsUrl, scrollTo, triggerGAEvent } from "../../helpers";

import questions from "../../data/quiz/set1.json";

import QuizAnswers from "./QuizAnswers";
import QuizQuestions from "./QuizQuestions";

const quizStyles = {
  backgroundImage: awsUrl + "/media/bg-coastline.jpg"
};

const Quiz = () => {
  const initialAnswers = questions.map((question) => {
    return question.answers.middle;
  });

  const [answers, setAnswers] = useState(initialAnswers);
  const [completed, setCompleted] = useState(false);
  const { userId, setQuizResultId, isTrade } = useUserContext();

  const setAnswer = (index, value) => {
    let answersArr = [...answers];
    if (value < 33) {
      answersArr[index] = questions[index].answers.left;
    } else if (value > 66) {
      answersArr[index] = questions[index].answers.right;
    } else {
      answersArr[index] = questions[index].answers.middle;
    }

    setAnswers(answersArr);
  };

  const postResultsToApi = () => {
    answers.forEach((answer) => {
      triggerGAEvent("plan-result_" + answer.title);
    });

    const result = answers.map((answer, idx) => {
      return {
        question: questions[idx].question,
        answer: answer.title
      };
    });

    postQuizData({ result, userId, is_trade: isTrade })
      .then((res) => {
        setQuizResultId(res.data.resultId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section
      id="section-your_day"
      className="min-h-screen w-full py-3 relative flex flex-col justify-center overflow-x-hidden"
      data-tag="section_5_explore"
      data-pixel="carni043+unique"
      style={quizStyles}
    >
      {!completed ? (
        <QuizQuestions
          questions={questions}
          setAnswer={setAnswer}
          submit={() => {
            scrollTo("section-your_day");
            postResultsToApi();
            setCompleted(true);
          }}
        />
      ) : (
        <QuizAnswers answers={answers} />
      )}
    </section>
  );
};

export default Quiz;
