import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getContactFormCaptcha, postContactForm } from "../../services/apiClient";
import { triggerGAEvent } from "../../helpers";
import { useUserContext } from "../../context/UserContext";

import FormInputGroup from "../../common/Elements/FormInputGroup";
import FormTextareaGroup from "../../common/Elements/FormTextareaGroup";
import InputError from "../../common/Elements/InputError";
import Button from "../../common/Elements/Button";
import Label from "../../common/Elements/Label";
import Input from "../../common/Elements/Input";

const ContactForm = ({ onComplete }) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    setError,
    clearErrors,
    setValue
  } = useForm();

  const [loadingCaptcha, setLoadingCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState({});
  const { isTrade } = useUserContext();

  useEffect(() => {
    const getCaptcha = async () => {
      if (!captcha.token && !loadingCaptcha) {
        setLoadingCaptcha(true);
        const captchaData = await getContactFormCaptcha();

        setCaptcha(captchaData);
        setValue("mathToken", captchaData.token);
        setLoadingCaptcha(false);
      }
    };
    getCaptcha().catch((e) => console.log(e));
  }, [captcha.token, loadingCaptcha, setValue]);

  const validation = {
    name: {
      required: "Name is required",
      pattern: {
        value: /^[A-Za-z\s\-']+$/,
        message: "Must be letters, apostrophe, or dash."
      }
    },
    email: {
      required: "Email is required",
      pattern: {
        value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        message: "Email is invalid"
      }
    },
    subject: {
      required: "Subject is required"
    },
    message: {
      required: "Message is required"
    },
    mathAnswer: { required: "You must answer the math question" }
  };

  const submitForm = (data) => {
    triggerGAEvent("contact-us_submit");
    data.isTrade = isTrade;
    postContactForm(data)
      .then((response) => {
        if (response.status === 204) {
          // Entry completed successfully
          onComplete();
        } else {
          // Something went wrong
          setError("general", { message: "Something went wrong. Please refresh try again." });
        }
      })
      .catch((e) => {
        if (e.response) {
          const { response } = e;
          if (
            response.status === 422 &&
            response.data.errors &&
            Object.keys(response.data.errors).length > 0
          ) {
            // Backend validation errors
            Object.keys(response.data.errors).forEach((key) => {
              setError(key, { type: "server", message: response.data.errors[key][0] });
            });
          } else {
            // Something went wrong
            setError("general", { message: "Something went wrong. Please refresh try again." });
          }
        } else {
          setError("general", { message: "Something went wrong. Please refresh try again." });
        }
      });
  };

  return (
    <section id="contact-us-form">
      <div className="text-center text-[19px]">
        <h2 className="font-bold uppercase text-[30px]">Have a Question?</h2>
        <p className="text-center text-[19px]">
          Complete and submit the form to let us know how we can help.
        </p>
      </div>
      <form
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(submitForm)(e);
        }}
        className="text-left"
      >
        <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-4">
          <FormInputGroup
            id="reg_form_name"
            label="Name"
            {...register("name", validation.name)}
            error={formErrors.name}
          />
          <FormInputGroup
            id="reg_form_email"
            label="Email"
            {...register("email", validation.email)}
            error={formErrors.email}
          />
          <FormInputGroup
            id="reg_form_subject"
            label="Subject"
            {...register("subject", validation.subject)}
            error={formErrors.subject}
            className="col-span-2"
            options={[
              { label: "Select a Subject", value: "" },
              { label: "Sweepstakes", value: "Sweepstakes" },
              { label: "Prizing", value: "Prizing" },
              { label: "Technical Difficulty", value: "Technical Difficulty" },
              { label: "Other", value: "Other" }
            ]}
          />
          <FormTextareaGroup
            id="reg_form_message"
            label="Message"
            {...register("message", validation.message)}
            error={formErrors.message}
            className="col-span-2"
          />
          <div className="col-span-2">
            <Label>
              Please correctly answer the provided mathematical question in order to proceed:
            </Label>
            <div className="mt-1 flex items-center justify-start">
              <div className="w-[200px]">
                {captcha.url && <img src={captcha.url} alt="Math Captcha" />}
                {!captcha.url && (
                  <div className="italic text-sm text-gray-400 text-center">Loading Captcha...</div>
                )}
              </div>
              <Input
                aria-label={`Please correctly answer the provided mathematical question in order to proceed: ${captcha.ariaLabel}`}
                id="math-answer"
                {...register("mathAnswer", validation.mathAnswer)}
                error={formErrors.mathAnswer}
                aria-describedby="math-answer-error"
                maxLength={`10`}
                className="md:w-32 ml-4"
              />
            </div>
            <InputError id="math-answer-error" className="text-left">
              {formErrors.mathAnswer && formErrors.mathAnswer.message}
            </InputError>
          </div>
        </div>
        <InputError>{formErrors.general}</InputError>
        <div className="text-center mt-4">
          <Button disabled={isSubmitting} as="submit" className="px-8">
            Submit
          </Button>
        </div>
      </form>
    </section>
  );
};

export default ContactForm;
