import React from "react";

import { awsUrl } from "../../helpers";

const ThankYou = () => {
  return (
    <div className="bg-sunset px-3 relative">
      <h1 className="relative z-10 text-white text-[30px] leading-[41px] lg:text-[45px] pt-[40px] lg:pt-[52px]">
        Thanks for entering
      </h1>
      <p className="relative z-10 text-white text-[19px] leading-[25px] max-w-[670px] mx-auto mt-[18px]">
        You could sail to Celebration Key<sup>&trade;</sup> on a Carnival cruise – and be one of the
        first to experience this brand-new destination.
      </p>
      <div className="absolute bottom-10 left-1/2 -translate-x-1/2 w-full text-white">
        <p className="uppercase block">
          Scroll to continue to explore Celebration&nbsp;Key<sup>&trade;</sup>
        </p>
        <img
          id="arrow-down"
          src={awsUrl + "/media/down-arrow-white.svg"}
          alt=""
          className="mt-3 w-[69px] h-10 mx-auto bounce"
        />
      </div>
    </div>
  );
};

export default ThankYou;
