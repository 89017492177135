import React from "react";
import "./rules.css";

import Footer from "../../components/Footer";
import { awsUrl, triggerGAEvent } from "../../helpers";
import { Link } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";

function Rules() {
  const { setCurrentPage, isPromoOver } = useUserContext();
  setCurrentPage("rules");

  return (
    <div className="w-full bg-lightest-blue min-h-screen flex flex-col">
      <div className="flex-grow">
        <div className="bg-[#A1CAFF] flex justify-center py-4">
          <Link
            to={"/"}
            className="block"
            onClick={() => {
              triggerGAEvent("nav_logo");
              window.scroll(0, 0);
            }}
          >
            <img
              src={awsUrl + "/media/logo-refined.png?v=1"}
              srcSet={awsUrl + "/media/logo-refined@2x.png?v=1 2x"}
              alt="Celebration Key logo"
              className="w-[250px]"
            />
          </Link>
        </div>
        <section
          id="official-rules"
          className="container mx-auto text-dark-blue px-4 max-w-6xl pb-10"
        >
          {isPromoOver && (
            <div className="pl-0 my-4 font-bold text-2xl text-center">Promotion has ended.</div>
          )}
          <h1 className="text-center text-tempo uppercase text-5xl font-bold my-10">
            Official Rules
          </h1>
          <div>
            <p>
              <strong>
                NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE YOUR CHANCE OF
                WINNING. OPEN ONLY TO LEGAL RESIDENTS OF THE FIFTY (50) UNITED STATES
                (&ldquo;U.S.&rdquo;), THE DISTRICT OF COLUMBIA, CANADA (EXCLUDING QUEBEC), AND
                PUERTO RICO WHO ARE AT LEAST TWENTY-ONE (21) YEARS OF AGE WITH A VALID EMAIL ADDRESS
                AS OF DATE OF ENTRY.
              </strong>
            </p>
            <ol>
              <li>
                <strong> ELIGIBILITY</strong>
                <strong>: </strong>The Sweepstakes is only open to legal residents of the fifty (50)
                U.S., the District of Columbia, Canada (excluding Quebec), and Puerto Rico who are
                at least twenty-one (21) years of age or older at the time of entry. Employees,
                agents, officers and directors of Carnival Cruise Line, a division of Carnival
                Corporation (&ldquo;Sponsor&rdquo;), Carnival Corporation, teamDigital Promotions,
                Inc. (&ldquo;teamDigital&rdquo; and/or &ldquo;Administrator&rdquo;) (collectively
                the &ldquo;Sweepstakes Entities&rdquo;), and their respective parents, subsidiaries,
                affiliates, representatives and advertising agencies, Sweepstakes fulfillment and
                marketing agencies (collectively, &ldquo;Released Parties&rdquo;) and their
                immediate families (parents, spouses, children and siblings and their respective
                spouses, regardless of where they reside), and household members, whether or not
                related, are not eligible to enter or win. Void in Quebec and where prohibited by
                law. Subject to all applicable federal, state and local laws. The information you
                provide as detailed below will be used for Sweepstakes purposes as well as for other
                promotions from the Sponsor.
              </li>
            </ol>
            <ol start="2">
              <li>
                <strong> SWEEPSTAKES PERIOD: </strong> The Sweepstakes begins on at 12:00:00 PM
                (noon) Eastern Time (“ET”) on January 29, 2024 and ends at 11:59:59 PM ET on August
                15, 2024 (the "Sweepstakes Period"). The Sweepstakes Period will be divided into
                five (5) entry periods (each an “Entry Period”) corresponding with one (1) launch
                period (“Launch Period”) and four (4) Portal Unlocks as described below in Section
                3. Each Entry Period will begin and end on the dates/times indicated on the Website
                (defined below) with the exception of the first Entry Period that will begin at
                12:00:00 PM ET on January 29, 2024 and the last Entry Period that will end at
                11:59:59 PM ET on August 15, 2024. Non-winning entries to any Entry Period will not
                roll over and will not be included in subsequent Entry Periods, if any.
              </li>
            </ol>
            <ol start="3">
              <li>
                <strong> TO ENTER: </strong>Visit Celebrationkey.carnival.com
                (&ldquo;Website&rdquo;) and follow the instructions provided to complete and submit
                an entry form (&ldquo;Entry Form&rdquo;) to receive one (1) entry. Following the
                conclusion of the existing Entry Period, a portal (&ldquo;Portal&rdquo;) will unlock
                which will open the next Entry Period (&ldquo;Portal Unlock&rdquo;). This will
                continue until all Portals have been unlocked. Once a Portal Unlock occurs, you will
                have the opportunity to visit the Website and, if you have previously completed an
                Entry Form, enter your email address to receive one (1) entry for that applicable
                Entry Period. If you have not yet completed an Entry Form, you can follow
                instructions to do so to receive one (1) entry.
              </li>
            </ol>
            <p>
              Submitting an entry constitutes your consent to participate in the Sweepstakes and
              consent for Sponsor to obtain, use, and transfer your name, address, email and other
              details for Sweepstakes administration purposes. Except as noted in these Official
              Rules, information collected for this Sweepstakes is used only for the purpose of
              Sweepstakes administration and winner notification, and will not be re-used, sold or
              shared in any manner by Sweepstakes Entities. By participating in the Sweepstakes,
              participant agrees to all of the terms and conditions of the Sponsor&rsquo;s Privacy
              Policy, which is available at{" "}
              <u>
                <a href="http://www.carnival.com/about-carnival/legal-notice/privacy-policy.aspx?icid=CC_Footer_82">
                  http://www.carnival.com/about-carnival/legal-notice/privacy-policy.aspx?icid=CC_Footer_82
                </a>
              </u>
              &nbsp;. In the event of any discrepancy between the Sponsor&rsquo;s Privacy Policy and
              these Official Rules, these Official Rules shall control and govern.
            </p>
            <ol start="4">
              <li>
                <strong>ENTRY LIMIT: </strong>There is a limit of one (1) entry per person per Entry
                Period for a maximum of five (5) entries throughout the Sweepstakes Period. Sponsor
                will not be responsible for late, lost, stolen, incomplete, misdirected,
                undelivered, delayed, garbled, damaged, inaccurate or undelivered entries, or for
                telephonic, human or computer failures, problems or errors, interruptions in service
                due to system upgrades, repairs, modifications or other causes, failures or
                malfunctions of connections, satellite, network, cable, Internet Service Provider
                (ISP), phones, phone lines or telephone systems, traffic congestion on the Internet,
                technical or mechanical malfunctions, jumbled, scrambled, delayed or misdirected
                transmissions, or network, typographical, printing, electronic, computer, mechanical
                or other malfunctions or errors, whether caused by equipment, programming, human
                error or otherwise relating to or in connection with the Sweepstakes, including,
                without limitation, (i) errors in connection with the administration of the
                Sweepstakes, entry processing, the announcement of a prize, the processing of
                entries, or in any other Sweepstakes-related materials; (ii) interrupted or
                unavailable network, server, Internet Service Provider (ISP), cable, satellite or
                other connections; (iii) Internet traffic congestion or any technical problem,
                including, without limitation, any injury or damage to any person&rsquo;s computer
                related to/resulting from Sweepstakes participation; (iv) typographical, printing or
                other errors or omissions in these Official Rules, in any Sweepstakes-related
                ads/materials; (v) human-processing error; (vi) electronic equipment, computer
                hardware or software failures; (vii) inaccurate entry information, whether caused by
                equipment, programming, human error, or otherwise; (viii) any condition caused by
                events that may cause the Sweepstakes to be disrupted or corrupted; or (ix) the
                inability of any winner to accept a prize. Persons found tampering with or abusing
                any aspect of this Sweepstakes or whose conduct potentially compromised the
                Sweepstakes or who are in violation of these Official Rules as solely determined by
                the Administrator, will be disqualified and all associated entries will be void.
                Every entry must be manually keystroked and manually entered by the entrant and
                repetitive, automated electronic submission of Sweepstakes entries is specifically
                prohibited. Use of automated entry software or programs is prohibited and entry
                through any such means is void. Released Parties are also not responsible for any
                incorrect or inaccurate information, whether caused by site users, tampering,
                hacking, or by any equipment or programming associated with or utilized in the
                Sweepstakes. Persons who tamper with or abuse any aspect of the Sweepstakes or
                Website or who are in violation of these Official Rules, as solely determined by
                Sponsor, will be disqualified and all associated entries will be void.
              </li>
            </ol>
            <ol start="5">
              <li>
                <strong>DRAWINGS AND ODDS OF WINNING:</strong> At the conclusion of the Sweepstakes
                Period, one (1) potential winner will be selected in a random drawing from among all
                eligible entries received throughout each Entry Period for a total of five (5)
                winners. Drawing will be conducted by Administrator, an independent judging
                organization whose decisions are final and binding in all matters relating to the
                Sweepstakes. Odds of winning a prize will depend on the total number of eligible
                entries received for each Entry Period. Limit one (1) prize per person, email
                address, family or household.
              </li>
            </ol>
            <ol start="6">
              <li>
                <strong> FIVE (5) GRAND PRIZES: </strong>Each winner will receive winner’s choice
                (subject to availability) of one (1) Carnival Cruise embarking from a North American
                port (up to a maximum of seven (7) nights) for winner and one (1) guest in up to a
                Ocean View Room. Sponsor will only be responsible for payment of cruise fare, taxes,
                fees, and port expenses associated with winner's Cruise selection. Maximum
                Approximate Retail Value (“ARV”): $1,399 each.
              </li>
            </ol>
            <p>
              Associated costs for additional guest(s) are each winner’s responsibility. Applicable
              prize value may vary depending upon departure location, dates of travel, seasonal
              rates, duration of voyage and itinerary. Any difference between stated and actual
              value will not be awarded. Terms and conditions of cruise apply. Cruises must be
              booked within three (3) months of the date which winner is notified and sail by August
              15, 2025, or the prize(s) shall be forfeited. Reservations are subject to
              availability, blackout dates, and based on capacity restrictions. Sponsor does not
              guarantee the cruise will be available on the exact sailing dates requested by a
              winner. Certain itineraries may be unavailable, altered, or later cancelled due to
              governmental restrictions including, without limitation, those related to COVID-19.
              Cancellations within six (6) weeks of sailing will result in forfeiture of a prize.
              All travel is capacity controlled and subject to availability. Unless otherwise
              indicated in the above prize description, all other expenses not expressly provided
              for herein including, but not limited to, airfare, ground transportation, pre and post
              cruise accommodations, shore excursion fees, premium dining, spa services, gratuities,
              onboard expenditures, and any other incidental costs or other expenses not
              specifically set forth herein are the sole responsibility of each winner and/or their
              guest. Each winner and their guest must travel on same itinerary. Sponsor&rsquo;s
              booking, cancellation, and other applicable policies including without limitation,
              those with respect to vaccinations, COVID-19, health and safety, and all terms and
              conditions of the Sponsor&rsquo;s Standard Ticket Contract of Passage, found in the
              cruise booking documentation and at
              http://www.carnival.com/about-carnival/legal-notice/ticket-contract.aspx, as well as
              any other rules, regulations and guidelines of the Sponsor, including these Official
              Rules, shall be applicable to each prize. Each winner and their guest must (i) have
              all necessary identification and/or travel documents (including but not limited to a
              valid passport or other document) required for travel and (ii) comply with all
              check-in requirements, including, but not limited to, the presentation of a major
              credit card. Any portion of a prize not accepted by winner will be forfeited, and the
              Sponsor shall not be responsible for any inability of a winner to accept or use any
              portion of a prize for any reason. Any onboard credit issued as part of a prize must
              be used in full before the end of the cruise voyage or the remaining balance shall be
              void. No credits or refunds shall be provided for unused onboard credits. No cash
              redemptions. No stateroom upgrades or downgrades shall be permitted. Prizes are
              non-transferable, cannot be substituted (except at the sole discretion of the Sponsor
              who reserves the right to substitute a prize of equal or greater value), and may not
              be sold or combined with any other offer. No &ldquo;Vacation Guarantee&rdquo; or
              similar type offer shall apply. If a cruise is cancelled after ticketing of cruise,
              for any reason, Sponsor reserves the right to substitute a prize of equal or greater
              value. All details to be determined by Sponsor in its sole discretion. Each winner is
              responsible for any federal, state and local taxes, as well as any other costs and
              expenses associated with acceptance and use of prize not specified as covered as part
              of prize herein. Maximum ARV of all prizes awarded: $6,995.
            </p>
            <ol start="7">
              <li>
                <strong> PRIZE AWARDING &amp; NOTIFICATION:</strong> Potential winners are subject
                to verification and will be notified by email and/or telephone and must respond
                within five (5) calendar days from the date of notification or such winner(s) may be
                disqualified and the prize at hand awarded to an alternate winner. Sponsor will
                require each potential winner to provide evidence of eligibility (photocopy of a
                valid driver&rsquo;s license or other government-issued identification) and contact
                details (including, without limitation, a valid e-mail address, mailing address and
                telephone number) to enable Sponsor to award a prize to the potential winner in
                accordance with the Official Rules. Sponsor reserves the right (at its sole
                discretion) to disqualify any potential winner from any prize element, if a
                potential winner is determined to be ineligible to participate and/or win a prize.
                Potential winners will be required to execute and return an Affidavit of
                Eligibility, Liability and (where legal) Publicity Release within five (5) days of
                date of issuance of notification. If an eligible resident of Canada, a potential
                winner will be required to answer correctly, without assistance of any kind
                (mechanical or otherwise), a time limited mathematical skill testing question which
                will be administered by Sponsor by telephone at a mutually agreeable time. Each
                winner&rsquo;s guest may be required to execute and return a Liability/Publicity
                Release prior to travel. Guests must be of legal age of majority in their
                jurisdiction of residence (and at least twenty-one (21)) unless child or legal ward
                of winner). If a potential guest is a minor child or legal ward of the winner, the
                winner will be required to execute the Liability Release and Publicity Release
                (unless prohibited by law) on their behalf. If any potential winner fails to respond
                within the specified time period or cannot be contacted; is ineligible according to
                these Official Rules; fails to return the required documents within the specified
                time period; fails to answer correctly the skill testing question as determined by
                Sponsor; or Sponsor&rsquo;s sending of the required documents are returned as
                undeliverable; if any potential winner decides to decline a prize for any reason
                whatsoever; or if any potential winner otherwise fails to fully comply with these
                Official Rules, he/she will forfeit their prize and the prize may be awarded to an
                alternate winner from among all remaining eligible entries received for the
                applicable drawing, in the Sponsor&rsquo;s sole discretion. Once the official
                paperwork is received within the specified time period, verified, and Sponsor
                receives final approval by Administrator, then and only then will a potential winner
                be designated a winner in the Sweepstakes. Sponsor assumes no responsibility for an
                undeliverable prize resulting from a typographical error or change of address of
                entrant. Prize award is subject to verification of eligibility and compliance with
                these Official Rules.
              </li>
            </ol>
            <ol start="8">
              <li>
                <strong>GENERAL:</strong> By entering, each entrant agrees (1) that Sponsor and its
                designees shall have the right and permission to use in any manner (unless
                prohibited by law) entrant's name, voice, city and state of residence, and
                photographs and/or likeness for advertising and/or trade and/or Sweepstakes and/or
                any other purpose now or hereafter known anywhere in the world in perpetuity without
                further compensation, permission or notification to entrant or any third parties,
                except where prohibited by law; (2) to release the Released Parties from all
                liability and to indemnify, defend and hold harmless the Released Parties from and
                against any claim, action, liability, loss, injury or damage to entrant or any other
                person or entity, including, without limitation, infringement or violation of
                third-party rights or personal injury or death to entrant or any other person or
                damage to personal or real property, due in whole or in part, directly or
                indirectly, by reason of (a) the acceptance, possession, use or misuse of a prize;
                (b) participation in or administration of this Sweepstakes; (c) the exploitation of
                any of the rights granted herein; and (d) any element of this Sweepstakes; (3) to
                assume all liability for any injury or damage related to (2) (a)-(d); (4) to be
                bound by the Official Rules and the Sponsor&rsquo;s and/or Administrator&rsquo;s
                decisions which are final; (5) to waive all rights to claim or collect punitive,
                incidental, consequential or any other damages; (6) that all causes of action
                relating to this Sweepstakes will be resolved individually without resort to any
                form of class action; (7) that any and all claims, judgments, or awards shall be
                limited to actual out of pocket costs incurred, excluding attorneys' fees; and (8)
                that Florida law shall apply exclusively to the Sweepstakes and that any dispute
                with respect to the Sweepstakes shall be resolved in either the federal or state
                courts located in the County of Miami-Dade, State of Florida.
              </li>
            </ol>
            <p>
              Sponsor reserves the right in its sole discretion to cancel, terminate, modify, or
              suspend the Sweepstakes if fraud, technical failures or any other factor beyond
              Sponsor's reasonable control impairs or compromises the administration, security,
              integrity or fairness of the Sweepstakes (or portions thereof), as determined by
              Sponsor in its sole discretion, and limit entries to those submitted prior to the
              action taken, or to proceed in such a manner as may be deemed fair and equitable by
              Sponsor in its sole discretion. In the event the Sweepstakes is cancelled or
              terminated, notice will be posted online, and Sponsor reserves the right, at its
              discretion, to award the prizes in a random drawing from among all eligible
              non-suspect entries received up to time of such action. In the event there is a
              discrepancy or inconsistency between disclosures or other statements contained in any
              Sweepstakes materials and the terms and conditions of the Official Rules, the Official
              Rules shall prevail, govern and control. Entry information becomes property of
              Sponsor.
            </p>
            <p>
              Any entries suspected of being fraudulent (including those using robotic, automatic,
              programmed, or similar methods of participation) may be disqualified, based on
              determinations made solely by Sponsor. Sponsor reserves the right to prohibit the
              participation of an individual if fraud or tampering is suspected or if the individual
              fails to comply with any requirement of participation as stated herein or with any
              provision in these Official Rules. If an entrant submits more than the stated number
              of entries permitted,&nbsp;and/or if Sponsor suspects that an entrant attempted to
              obtain additional&nbsp;entries by using multiple e-mail addresses, identities or any
              other method, all suspect entries submitted by the entrant may be declared null and
              void.&nbsp;
            </p>
            <p>
              <strong>NOTICE TO ENTRANTS:</strong> ANY ATTEMPT BY AN INDIVIDUAL TO DELIBERATELY
              DAMAGE ANY SWEEPSTAKES-RELATED WEBSITE OR UNDERMINE THE LEGITIMATE OPERATION OF THIS
              SWEEPSTAKES IS A VIOLATION OF CRIMINAL AND CIVIL LAWS, AND SHOULD SUCH AN ATTEMPT BE
              MADE, SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES (INCLUDING ATTORNEYS&rsquo; FEES) AND
              OTHER REMEDIES FROM ANY SUCH INDIVIDUAL REPONSIBLE FOR THE ATTEMPT TO THE FULLEST
              EXTENT PERMITTED BY LAW.
            </p>
            <ol start="9">
              <li>
                <strong> LIMITATION OF LIABILITY; DISCLAIMER OF WARRANTIES:</strong> IN NO EVENT
                WILL THE RELEASED PARTIES AND/OR THEIR RESPECTIVE PARENT COMPANIES, SUBSIDIARIES,
                AFFILIATES, PARTNERS, REPRESENTATIVES, AGENTS, SUCCESSORS, ASSIGNS, EMPLOYEES,
                OFFICERS AND DIRECTORS, BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES OR LOSSES OF ANY
                KIND, INCLUDING DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
                ARISING OUT OF THE SWEEPSTAKES, AND/OR DOWNLOADING FROM AND/OR PRINTING MATERIAL
                DOWNLOADED FROM ANY WEBSITES ASSOCIATED WITH THE SWEEPSTAKES. IN NO EVENT SHALL THE
                RELEASED PARTIES&rsquo; TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF
                ACTION EXCEED FIVE DOLLARS ($5.00 USD). WITHOUT LIMITING THE FOREGOING, THIS
                SWEEPSTAKES AND PRIZES ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND FROM
                SPONSOR, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT. SOME JURISDICTIONS MAY NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
                LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR EXCLUSION OF IMPLIED
                WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.
                CHECK YOUR LOCAL LAWS FOR ANY RESTRICTIONS OR LIMITATIONS REGARDING THESE
                LIMITATIONS OR EXCLUSIONS.
              </li>
            </ol>
            <ol start="10">
              <li>
                <strong>WINNERS LIST:</strong> For the names of the winners, visit
                winners.teamdigital.com/CelebrationKey no later than September 30, 2024.
              </li>
            </ol>
            <ol start="11">
              <li>
                <strong>SPONSOR</strong>
                <strong>:</strong> Carnival Cruise Line, a division of Carnival Corporation,
                Carnival Place, 3655 NW 87th Avenue, Miami, FL 33178-2428. Sponsor is not
                responsible for the administration of the Sweepstakes or the selection of winners.
              </li>
            </ol>
            <ol start="12">
              <li>
                <strong>ADMINISTRATOR:</strong> teamDigital Promotions, Inc., 6 Berkshire Blvd,
                Bethel, CT 06801.
              </li>
            </ol>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Rules;
