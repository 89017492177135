import React, { useEffect, useRef, useState } from "react";
import { useUserContext } from "../context/UserContext";

const Preloader = ({ onComplete }) => {
  const preloaderTime = 5000; // milliseconds
  const preloaderRef = useRef();
  const [yPos, setYPos] = useState(100);
  const { currentPortal } = useUserContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, preloaderTime);

    return () => clearTimeout(timer);
  }, [onComplete]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (yPos > 0) {
        setYPos((prevCounter) => prevCounter - 1);
      } else {
        clearInterval(interval);
      }
    }, 40);

    return () => clearInterval(interval);
  }, [yPos, setYPos]);

  return (
    <div ref={preloaderRef} id="preloader" className={`fixed w-full h-full z-[1000] bg-dark-blue `}>
      <div
        className="w-[100px] h-[100px] flex items-center justify-center relative"
        id="preloader-container"
      >
        {currentPortal.key === "retail" || currentPortal.key === "family-friendly" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 218.8 223.1"
            preserveAspectRatio="none"
            max-width="220"
            width="200"
            max-height="220"
          >
            <defs>
              <clipPath id="revealStar">
                <rect id="whiteBox" x="0%" y={yPos} width="200" height="220" fill="white" />
              </clipPath>
            </defs>

            <g clipPath="url(#revealStar)">
              <path
                className="star"
                fill="white"
                d="M190.5,165.6c1.3-3.1,5.3-4.1,7.3-6.9c1.8-2.7,1.7-6.1,2-9.3c0.5-6.6,1.2-16.8,1.6-22.2c0-1.5,0.7-4.8-1.7-4.3
              c-7.1,2.9-15.5,8.3-22.4,11.6c-4.8,2.6-4.7-1-5.2-4.2c-0.5-3.8-1.1-9.5-1.6-13.9c-0.2-2.8-1.1-6.8,2.2-8c2.7-1,6.3-0.8,7.6-3.9
              c1.3-3.3-1.8-9.6-3.1-12.8c-1.4-3.7-4.3-5-7.2-5.1c-8.5-0.2-18.7,0-30.9-0.1c-2.9,0-11.9,0.6-13.2-4.4c-0.8-3.1-2.1-10.3,0.8-11.9
              c2.1-1,11.9,5.4,12.5,3c0.1-6.3-13.5-16-3.8-19.8c2.3-1.3,16.3-3.6,10.1-7.4c-4.6-3-17.2-2.6-13.4-11.2c0.5-2.4,8.1-12.8,3.2-12.7
              c-5.7,0.5-15.1,11.2-18.5,1.6c-1.3-2.5-3.1-13.7-6.3-11.5c-3.7,3.2-3.1,16.3-9.8,15.4c-4.6-0.6-9.9-5.9-14.2-5.5
              c-3.4,2.3,6.8,13.2,4.6,17.2c-2,3.9-9.3,4.3-12.9,6.2c-4.1,1.8-2.7,3.7,0.8,5c4.7,2.1,14.9,2.6,11.7,10.1C90,63.4,83.1,72.7,85.1,75
              c1.1,0.9,9-5.1,10.8-4.3c2.9,1.6,2.3,8.6,1,11.9c-1.4,3.6-4.5,3.8-8.9,3.8c-13.1,0.1-27.9-0.2-37.8,0c-2.9,0.1-4.5,2.5-5.5,5.1
              c-1.5,3.8-3.5,9.6-2.2,12.9c1.6,4.1,7,2.3,9.4,5.3c1,1.5,0.7,3.7,0.6,5.5c-0.5,4.5-1.2,11-1.7,15.3c-0.5,3.3-0.4,7-5.2,4.3
              c-2.6-1.3-5.1-2.7-8.3-4.4c-3.9-2-8.3-4.5-11.9-6.3c-1.9-0.8-4-2.4-4.4,0.1c-0.1,7,1.2,17.4,1.6,25.6c0.2,3.1,0.4,7.2,2.4,9.4
              c2.1,2.5,6.1,3.5,7.4,6.4c1.4,3.1-0.6,31.5-12.5,40c-3.4,2.4-2.9,4.6,2.3,4.6c15.8,0.1,44.8,2.1,47.4,1c1.9-0.8,2.3-4.4,2.6-7
              c1.5-18,5.4-40.6,36.1-42.1c34.2-1.7,41,19,42.7,42.6c0.5,5.6,1.9,6.8,7,6.9c11.7,0.2,35.8,1,39.6-0.5c2.4-0.9-3.3-3.1-4.9-9.8
              C189.8,189.3,188,171.8,190.5,165.6z M67.6,147.2c-3.2-1.8-3.9-8.1-4.8-11.7c-1.3-6.4,1.2-7.7,7.7-8.8c2.6-0.5,5.4-1.2,8-1.1
              c2.7,0.1,3.9,1.6,4.7,4.4c0.6,2.4,1.1,5.6,1.3,8.2c0.1,1.8,0.2,3.5-0.5,5.1c-1.2,2.4-3.7,2.7-7.1,3.4C74,147,70.6,148.4,67.6,147.2
              L67.6,147.2z M120.4,142.6c-1,0.9-2.4,1.1-3.9,1.1c-2.3,0.1-4.8,0-7.1,0c-3.4-0.1-5.9-0.2-7.4-2.3c-1.4-2.3-1.3-5-1.5-7.8
              c-0.1-1.7-0.2-3.3,0-4.8c0.2-3.6,1.8-5.5,5.3-6.1c3.1-0.5,6.7-0.3,9.9-0.3c5.7-0.1,6.8,0.9,6.8,5.6c0,1.9-0.1,4-0.2,5.9
              C122,136.8,122.2,140.8,120.4,142.6L120.4,142.6z M111.4,61.5c-8.6,0.1-14.8-8.7-12-16.7c3.7-11.4,20.1-11.5,24-0.2
              C126.3,52.7,120.2,61.6,111.4,61.5L111.4,61.5L111.4,61.5z M155,147.5c-2.9,1.1-6.2-0.3-9.2-0.7c-3.4-0.7-6-1-7.1-3.4
              c-0.7-1.6-0.6-3.3-0.5-5.1c0.3-2.5,0.7-5.8,1.4-8.2c1-3.9,3.3-4.9,6.9-4.3c1.8,0.2,3.8,0.6,5.6,1c6.1,1.2,9.3,2.1,8,8.4
              C159.2,138.8,158.3,145.8,155,147.5L155,147.5z"
              />
            </g>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 94.53 89.08"
            preserveAspectRatio="none"
            max-width="200"
            width="200"
            max-height="200"
          >
            <defs>
              <clipPath id="revealStar">
                <rect id="whiteBox" x="0%" y={yPos} width="200" height="200" fill="white" />
              </clipPath>
            </defs>
            <g clipPath="url(#revealStar)">
              <path
                className="star"
                fill="white"
                d="m94.26,33.8c-.91-3.34-4-5.59-7.48-5.47,0,0-16.12-.46-21.86-5-5.74-4.53-9.92-17.74-9.92-17.74-.78-3.28-3.71-5.59-7.08-5.59s-6.3,2.31-7.08,5.59c0,0-3.92,12.7-10.16,17.74-5.78,4.66-22.76,4.51-22.76,4.51-3.54-.13-6.72,2.17-7.65,5.59-.94,3.42.62,7.04,3.75,8.71,0,0,14.02,6.61,16.07,13.78,2.05,7.17-3.13,22.61-3.13,22.61-1.32,3.23-.2,6.95,2.68,8.93,2.88,1.97,6.75,1.67,9.29-.73,0,0,10.89-10.88,18.87-10.67,7.98.2,19.06,10.97,19.06,10.97,1.43,1.36,3.29,2.05,5.16,2.05,1.47,0,2.95-.43,4.23-1.31,2.91-2,4.04-5.75,2.71-9.02,0,0-7.03-14.64-3.45-22.81s15.09-13.62,15.09-13.62c3.06-1.63,4.58-5.17,3.66-8.51h0Z"
              />
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};

export default Preloader;
