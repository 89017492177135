import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

const CountdownToOpening = () => {
  const dateObject = useMemo(() => {
    return dayjs("2025-07-19 00:00:00");
  }, []);

  const [timeLeft, setTimeLeft] = useState({});
  const [secondsToOpening, setSecondsToOpening] = useState(dateObject.diff(dayjs(), "second"));

  useEffect(() => {
    if (dateObject.isAfter(dayjs())) {
      const interval = setInterval(() => {
        setSecondsToOpening((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [dateObject]);

  useEffect(() => {
    let time;
    if (secondsToOpening < 0) {
      time = {
        days: "00",
        hours: "00",
        minutes: "00"
      };
    } else {
      time = {
        days: pad(Math.floor(secondsToOpening / 86400), 2),
        hours: pad(Math.floor(secondsToOpening / 3600) % 24, 2),
        minutes: pad(Math.floor((secondsToOpening % 3600) / 60), 2)
      };
    }

    setTimeLeft(time);
  }, [secondsToOpening]);

  const pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  };

  return (
    <div
      id="countdown-to-opening"
      className="w-full p-3 bg-gradient-to-b from-dark-blue-2 via-80% via-dark-blue to-dark-blue flex items-center justify-center text-white text-center"
    >
      <div className="">
        <h2 className="text-[18px] mb-3 leading-tight text-millik">
          Paradise is less than <span className="inline-block">one year away</span>
        </h2>
        <div className="flex items-center justify-center leading-none uppercase">
          <div className="w-[90px]">
            <div className="text-[35px] font-semibold">{timeLeft.days}</div>
            <div className="text-[12px] mt-1">Days</div>
          </div>
          <div className="h-[50px] w-[2px] bg-gradient-to-b from-transparent via-white to-transparent"></div>
          <div className="w-[90px]">
            <div className="text-[35px] font-semibold">{timeLeft.hours}</div>
            <div className="text-[12px] mt-1">Hours</div>
          </div>
          <div className="h-[50px] w-[2px] bg-gradient-to-b from-transparent via-white to-transparent"></div>
          <div className="w-[90px]">
            <div className="text-[35px] font-semibold">{timeLeft.minutes}</div>
            <div className="text-[12px] mt-1">Minutes</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownToOpening;
