import { useEffect, useState } from "react";
import { triggerFloodLightEvent, triggerGAEvent } from "../helpers";

export const useSectionPixel = (isTrade) => {
  const [pixelImg, setPixelImg] = useState();

  useEffect(() => {
    let sections = document.querySelectorAll("section");
    let config = {
      root: null,
      rootMargin: "0px 0px -50% 0px",
      threshold: 0
    };
    //trigger GA for each section
    const onChange = (changes, observer) => {
      changes.forEach((change) => {
        if (change.intersectionRatio > 0) {
          const dataTag = change.target.getAttribute("data-tag");
          const dataPixel = change.target.getAttribute("data-pixel");

          triggerGAEvent(dataTag);

          if (isTrade === false && dataPixel !== null) {
            triggerFloodLightEvent(dataPixel);
            /*** TTD **/
            if (dataTag === "section_7_video") {
              document.getElementById("ttd-pixel").innerHTML =
                '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:8wnbmfa&fmt=3"/>';
            } else if (dataTag === "section_6_sliders") {
              document.getElementById("ttd-pixel").innerHTML =
                '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:901ftvu&fmt=3"/>';
            } else if (dataTag === "section_5_explore") {
              document.getElementById("ttd-pixel").innerHTML =
                '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:2cvpaun&fmt=3"/>';
            } else if (dataTag === "section_4_sweeps") {
              document.getElementById("ttd-pixel").innerHTML =
                '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:9vq9kf6&fmt=3"/>';
            } else if (dataTag === "section_3_portal") {
              document.getElementById("ttd-pixel").innerHTML =
                '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:i56m9lg&fmt=3"/>';
            } else if (dataTag === "section_2_accordion") {
              document.getElementById("ttd-pixel").innerHTML =
                '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:x6r4b16&fmt=3"/>';
            } else {
              document.getElementById("ttd-pixel").innerHTML =
                '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:oq3ul6b&fmt=3"/>';
            }
          }

          observer.unobserve(change.target);
        }
      });
    };

    const observer = new IntersectionObserver(onChange, config);
    sections.forEach((section) => observer.observe(section));

    return () => {
      observer.disconnect();
    };
  }, [isTrade, setPixelImg, pixelImg]);
};
