import { useEffect, useState } from "react";

const usePersistentState = (cookieName, defaultValue) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    let val;
    try {
      val = JSON.parse(window.localStorage.getItem(cookieName));
    } catch (e) {
      val = null;
    }
    setValue(val);
  }, [cookieName]);

  useEffect(() => {
    try {
      window.localStorage.setItem(cookieName, value);
    } catch (e) {
      console.error("Error saving to local storage", e);
    }
  }, [value, cookieName]);

  return [value, setValue];
};

export default usePersistentState;
