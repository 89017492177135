import React, { forwardRef, useState } from "react";
import InputError from "./InputError";

const FormCheckboxGroup = forwardRef(
  ({ name, value, onChange, defaultChecked, className, id, error, children }, ref) => {
    const errorId = id + "-error";

    const [checked, setChecked] = useState(defaultChecked);
    const [elementFocused, setElementFocused] = useState(false);

    const bocClasses = `${error ? "border-red-500" : "border-[#0680B5]"} ${
      elementFocused && "ring-2 ring-blue"
    } block border w-[30px] h-[30px] bg-white rounded-sm`;

    return (
      <div className={className}>
        <label className="w-full flex cursor-pointer">
          <span className="block flex-shrink-0 w-10">
            <input
              tabIndex={`0`}
              className="opacity-0 absolute"
              ref={ref}
              type="checkbox"
              id={id}
              name={name}
              value={value}
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
                onChange(e);
              }}
              aria-describedby={error ? "error" : ""}
              onFocus={() => setElementFocused(true)}
              onBlur={() => setElementFocused(false)}
            />
            <span className={bocClasses}>
              {checked && (
                <svg
                  className="w-[30px] h-[30px] text-blue"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 10L7 13L14 4"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </span>
          </span>
          <span className="block text-[13px]">{children}</span>
        </label>
        <InputError id={errorId} className="text-left">
          {error && error.message}
        </InputError>
      </div>
    );
  }
);

export default FormCheckboxGroup;
