import React from "react";
import { CopyBlock, PortalList } from "../DiscoverYourParadise";

const DiscoverYourParadise = () => {
  return (
    <div id="section-discover" className="bg-white">
      <CopyBlock />
      <PortalList />
    </div>
  );
};

export default DiscoverYourParadise;
