/** @format */

import React, { useEffect } from "react";

import { awsUrl } from "../helpers";

const Video = ({
  name,
  autoplay = false,
  videoplaying = true,
  id,
  height = "full",
  gradientTopper,
  position
}) => {
  useEffect(() => {}, [videoplaying]);

  const videoPos = position === "top" ? `object-position:top` : "";

  const videoHeight = height !== "full" ? `${height} ` : "h-full ";

  try {
    let isMobile =
      window.matchMedia("(max-width: 992px)").matches ||
      window.matchMedia("(pointer: coarse)").matches;

    // Import image on demand
    const video = !isMobile
      ? `${awsUrl}/media/${name}.mp4?v=7.1`
      : `${awsUrl}/media/${name}-m.mp4?v=3`;

    const poster = !isMobile
      ? `${awsUrl}/media/${name}-poster.jpg`
      : `${awsUrl}/media/${name}-m-poster.jpg?v=1`;

    if (!video) return null;

    const playVideo = (event) => {
      if (!isMobile && videoplaying) {
        event.target && event.target.play();
      }
    };

    if (autoplay && videoplaying) {
      return (
        <div
          className={`${videoHeight}`}
          dangerouslySetInnerHTML={{
            __html: `
          <video
            width="100%"
            height="100%"
            autoplay loop muted playsinline
            preload="meta"
            poster=${poster}
            style=${videoPos}

          >
            <source src=${video + "#t=0.1"} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
				 `
          }}
        ></div>
      );
    } else {
      return (
        <div className={`${videoHeight}` + gradientTopper ?? `z-20`}>
          <video
            id={"video" + name}
            onMouseOver={playVideo}
            className={name}
            width="100%"
            height="100%"
            muted
            loop
            preload="meta"
            poster={poster}
            src={video}
            style={{ videoPos }}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }
  } catch (error) {
    console.log(error);
    console.log(`Video with name "${name}" does not exist`);

    return null;
  }
};

export default Video;
