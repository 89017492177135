import React from "react";
import PropTypes from "prop-types";

const ModuleCopyBlock2 = ({ children, className, width = "full", gradient = true }) => {
  const widthClasses = {
    "1/3": "w-1/3",
    "5/12": "w-5/12",
    "1/2": "w-1/2",
    "2/3": "w-2/3",
    "3/4": "w-3/4",
    full: "w-full mx-auto text-center"
  };

  return (
    <div className={`absolute z-30 h-full w-full flex flex-col justify-end  ` + className}>
      <div className="pb-16 px-[24px] lg:px-10 pointer-events-auto relative z-40 bg-dark-blue lg:bg-transparent  lg:from-dark-blue gradient-topper ">
        <div className={`max-w-4xl lg:px-4 ${widthClasses[width]}`}>{children}</div>
      </div>
      {gradient ? (
        <div className="absolute w-full h-[70%] lg:h-[50%] bg-gradient-to-t from-dark-blue -z-[1] -bottom-[1px]"></div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

ModuleCopyBlock2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  width: PropTypes.string
};

export default ModuleCopyBlock2;
