import React, { forwardRef } from "react";
import Input from "./Input";
import Label from "./Label";
import InputError from "./InputError";
import Select from "./Select";

const FormInputGroup = forwardRef((props, ref) => {
  const errorId = props.id + "-error";

  return (
    <div className={props.className}>
      <Label htmlFor={props.id} className={props.labelHidden && "sr-only"}>
        {props.label}
      </Label>
      {props.options ? (
        <Select
          {...props}
          error={props.error}
          ref={ref}
          aria-describedby={errorId}
          options={props.options}
          className="w-full"
        />
      ) : (
        <Input
          {...props}
          error={props.error}
          ref={ref}
          aria-describedby={errorId}
          className="w-full"
          htmlFor={props.id}
        />
      )}
      <InputError id={errorId} className="text-left">
        {props.error && props.error.message}
      </InputError>
    </div>
  );
});

export default FormInputGroup;
