import React from "react";

const InputError = ({ children, className, id }) => {
  const classes = `${className ? className : ""} text-xs w-full text-red bg-[#fef2f2] p-1 mt-2`;

  return children ? (
    <div id={id} className={classes} role="alert">
      <em>{children}</em>
    </div>
  ) : null;
};

export default InputError;
