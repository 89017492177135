import React, { useRef } from "react";
import { PortalListItem } from "../DiscoverYourParadise";
import { useGSAP } from "../../gsap/react";
import { gsap } from "../../gsap";

const PortalList = () => {
  const portals = [
    {
      name: "Paradise Plaza",
      key: "paradise-plaza",
      href: "/portals/paradise-plaza",
      eventTag: "portal_paradise-plaza",
      titleLogo: "CK_Lockups_TM_Paradise_Plaza.svg"
    },
    {
      name: "Calypso Lagoon",
      key: "calypso-lagoon",
      href: "/portals/calypso-lagoon",
      eventTag: "portal_calypso-lagoon",
      titleLogo: "CK_Lockups_TM_Calypso_Lagoon.svg"
    },
    {
      name: "Lokono Cove",
      key: "lokono-cove",
      href: "/portals/lokono-cove",
      eventTag: "portal_lokono-cove",
      titleLogo: "CK_Lockups_TM_Lokono_Cove.svg"
    },
    {
      name: "Starfish Lagoon",
      key: "starfish-lagoon",
      href: "/portals/starfish-lagoon",
      eventTag: "portal_starfish-lagoon",
      titleLogo: "CK_Lockups_TM_Starfish_Lagoon.svg"
    },
    {
      name: "Private Portal",
      key: "pearl-cove",
      href: "/portals/pearl-cove",
      eventTag: "portal_private-portal",
      titleLogo: "CK_Lockups_TM_Pearl_Cove.svg",
      cover: "object-[50%_40%]"
    }
  ];

  const tl = useRef();

  useGSAP(() => {
    tl.current = gsap.timeline({
      scrollTrigger: {
        start: "top 80%",
        end: "+=150%",
        trigger: "#discover-portals"
      }
    });
    portals.forEach((portal) => {
      tl.current.from(
        `#discover-portal-${portal.key}`,
        {
          duration: 0.75,
          y: 80,
          autoAlpha: 0,
          opacity: 0,
          delay: 0.3,
          ease: "ease.in"
        },
        "<"
      );
    });
  }, {});

  return (
    <div className="relative">
      <ul id="discover-portals" className="bg-dark-blue lg:flex lg:h-[546px] justify-evenly">
        {portals &&
          portals.map((portal, idx) => (
            <PortalListItem
              key={portal.name}
              id={`discover-portal-${portal.key}`}
              portal={portal}
              idx={idx}
            />
          ))}
      </ul>
    </div>
  );
};

export default PortalList;
