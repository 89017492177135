import React from "react";
import PropTypes from "prop-types";

import { ModuleCopyBlock, ModuleCopyBody, ModuleCopyHeadline } from "../Parts";
import Video from "../../../../common/Video";

const ModuleAccordionPanel = ({ isInitial, isActive, panel, isPlaying, setActive, setInitial }) => {
  const setStates = () => {
    setActive();
    setInitial();
  };
  return (
    <li
      className={`w-1/3 h-full relative ${isActive && !isInitial ? `isActive` : `notActive`} ${
        isInitial ? `isInitial` : ``
      }`}
      onMouseEnter={() => {
        setStates();
      }}
      onMouseLeave={() => {
        setInitial();
        setActive();
      }}
    >
      <Video poster={panel.video} name={panel.video} autoplay={isPlaying} />{" "}
      <ModuleCopyBlock className={`${isActive ? `opacity-100` : `opacity-0`} `}>
        <div className="xl:w-[400px] max-w-[400px] relative">
          <ModuleCopyHeadline theme={`light`} align={`left`}>
            {panel.headline}
          </ModuleCopyHeadline>
          <ModuleCopyBody theme={`light`} align={`left`}>
            {panel.bodyCopy}
          </ModuleCopyBody>
        </div>
      </ModuleCopyBlock>
    </li>
  );
};

ModuleAccordionPanel.propTypes = {
  isInitial: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  panel: PropTypes.object.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  setInitial: PropTypes.func.isRequired
};

export default ModuleAccordionPanel;
