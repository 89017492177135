/** @format */

export const awsUrl = process.env.REACT_APP_ASSET_URL;

export const triggerGAEvent = (lbl) => {
  if (typeof gtag !== "undefined") {
    window.gtag("event", lbl, {
      send_to: ["carnivalG4", "tdG4", "tdTrade"]
    });

    window.gtag("event", "click", {
      category: "navigation",
      event_category: "navigation",
      event_label: lbl,
      value: lbl,
      send_to: ["carnivalUA"]
    });
  }
};

export const triggerFloodLightEvent = (pixel) => {
  if (typeof gtag !== "undefined") {
    window.gtag("event", "conversion", {
      allow_custom_scripts: true,
      u1: "[Number of Passengers]",
      u10: "[Military]",
      u11: "[Page URL]",
      u12: "[ga_cilent_id]",
      u13: "[vifp_number]",
      u14: "[Sail ID]",
      u2: "[Duration]",
      u3: "[Port]",
      u4: "[Ship]",
      u5: "[Destination]",
      u6: "[Sail Date]",
      u7: "[Stateroom]",
      u8: "[Booking ID]",
      u9: "[Home State]",
      send_to: "DC-10750860/carni0/" + pixel
    });
  }
};

export const triggerGAPageViewEvent = (pageTitle) => {
  if (typeof gtag !== "undefined") {
    window.gtag("event", "page_view", {
      page_title: pageTitle,
      page_location: window.location.href + pageTitle + "",
      send_to: ["carnivalUA"]
    });

    window.gtag("event", "page_view", {
      page_title: pageTitle,
      page_location: window.location.href + pageTitle + "",
      page_path: window.location.pathname + window.location.hash,
      send_to: ["carnivalG4", "tdG4", "tdTrade"]
    });
  }
};

export const scrollTo = (section) => {
  const el = document.getElementById(section);

  if (el) {
    el.setAttribute("tabindex", 0);
    el.focus();
    setTimeout(() => {
      window.scrollTo({
        top: el.offsetTop - 55,
        behavior: "smooth"
      });
    }, 200);
  } else {
    const el = document.getElementById("section-intro");
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }, 200);
  }
};
