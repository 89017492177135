import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ModuleCopyBlock,
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleGalleryThumbnail,
  ModuleSection
} from "./Parts";
import Video from "../../../common/Video";

const Gallery = ({ galleryItems, headline, bodyCopy, isPlaying, theme }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  return (
    <ModuleSection className="relative">
      <Video
        poster={galleryItems[currentItemIndex].poster}
        name={galleryItems[currentItemIndex].video}
        autoplay={isPlaying}
      />

      <ModuleCopyBlock width="5/12">
        <ModuleCopyHeadline theme={theme}>{headline}</ModuleCopyHeadline>
        <ModuleCopyBody theme={theme}>{bodyCopy}</ModuleCopyBody>
        <div className="flex flex-wrap mt-4">
          {galleryItems.map((item, idx) => (
            <ModuleGalleryThumbnail
              key={idx}
              idx={idx}
              active={currentItemIndex === idx}
              thumbnail={item.thumbnail}
              setCurrentItemIndex={setCurrentItemIndex}
            />
          ))}
        </div>
      </ModuleCopyBlock>
    </ModuleSection>
  );
};

Gallery.propTypes = {
  galleryItems: PropTypes.arrayOf(
    PropTypes.shape({
      video: PropTypes.string.isRequired,
      poster: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired
    })
  ).isRequired,
  isPlaying: PropTypes.bool.isRequired,
  headline: PropTypes.string.isRequired,
  bodyCopy: PropTypes.string.isRequired
};

export default Gallery;
