import React from "react";
import PropTypes from "prop-types";

const ModuleCopyHeadline = ({ children, theme, align = "center", id }) => {
  const textColor = theme === "light" ? "text-white" : "text-dark-blue";

  return (
    <h2
      id={id}
      aria-label={children}
      className={`text-millik text-[32px] leading-[35px] md:text-[45px] md:leading-[50px] mb-4 lg:mb-6 ${textColor} text-${align}`}
    >
      {children}
    </h2>
  );
};

ModuleCopyHeadline.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  theme: PropTypes.oneOf(["light", "dark"]).isRequired
};

export default ModuleCopyHeadline;
