import React, { useEffect, useState } from "react";
import { getWeatherData } from "../../services/apiClient";

const Weather = () => {
  const [weather, setWeather] = useState(null);

  useEffect(() => {
    const getWeather = async () => {
      const weatherData = await getWeatherData();
      setWeather(weatherData);
    };
    getWeather().catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-full text-[22px] md:text-[28px] text-center px-4 pt-12 pb-8 md:flex md:items-center md:justify-center border-b border-b-lighter-blue">
      <div className="w-full text-millik md:w-1/2 md:text-right md:border-r md:border-r-lighter-blue md:pr-10">
        It’s always paradise at
        <br />
        Celebration Key<sup>&trade;</sup>
      </div>
      <div className="w-full md:w-1/2 md:text-left md:border-l md:border-l-white md:pl-10">
        {weather && (
          <div className="flex items-center justify-center md:justify-start mt-3 md:mt-0">
            {weather.icon && (
              <div>
                <img src={weather.icon} alt={`Weather - ${weather.type}`} className="w-[80px]" />
              </div>
            )}
            <div className="font-bold ml-4 mr-6">
              {weather.temp.f}&deg;F / {weather.temp.c}&deg;C
            </div>
          </div>
        )}
        {!weather && (
          <div className="flex items-center justify-center md:justify-start mt-6 md:mt-0">
            <div className="font-bold ml-2 mr-6 italic text-lg text-gray-300">
              Loading Weather...
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Weather;
