import React from "react";

const ThankYou = (props) => {
  return (
    <section id="contact-us-thank-you">
      <div className="text-center text-[19px]">
        <h2 className="font-bold uppercase text-[30px]">We have your message</h2>
        <p className="text-center text-[19px]">
          Thanks for your message. You will receive a reply via email within 3 business days. To
          avoid communications entering spam or junk folders, please be sure to add
          support@teamdigital.com to your address book.
        </p>
      </div>
    </section>
  );
};

export default ThankYou;
