import React from "react";
import { AlreadyEntered, EmailForm, RegistrationForm, ThankYou } from "./index";
import { useSweepstakesContext } from "../../context/SweepstakesContext";

const Sweepstakes = () => {
  const { showSection } = useSweepstakesContext();

  return (
    <section
      id="sweeps"
      className="w-full bg-lightest-blue relative"
      data-tag="section_4_sweeps"
      data-pixel="carni042+unique"
    >
      {showSection === "email" && <EmailForm />}
      {showSection === "registration" && <RegistrationForm />}
      {showSection === "already-entered" && <AlreadyEntered />}
      {showSection === "thank-you" && <ThankYou />}
    </section>
  );
};

export default Sweepstakes;
