import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { awsUrl, triggerGAEvent } from "../../helpers";
import { useScrollDetectors } from "../../hooks/useScrollDetectors";
import { useUserContext } from "../../context/UserContext";

import { UnlockedPortalWrapper } from "../../components/UnlockedPortal/Parts";
import UnlockedPortalIntro from "../../components/UnlockedPortal/UnlockedPortalIntro";
import Button from "../../common/Elements/Button";

import { useGSAP } from "../../gsap/react";
import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";

import { FlyThrough, SideBySide, Overlay } from "../../components/UnlockedPortal/Modules";
import {
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleSideHalf
} from "../../components/UnlockedPortal/Modules/Parts";

gsap.registerPlugin(SplitText, useGSAP);

const Retail = () => {
  const { setCurrentPage, setAllVideosPlaying, allVideosPlaying } = useUserContext();
  setCurrentPage("starfish-lagoon");
  const headlineTl = useRef();

  useScrollDetectors("retail-portal");

  useEffect(() => {
    document.title = "Carnival | Celebration Key™ | Starfish Lagoon";
  }, []);

  useGSAP(() => {
    let titleRef = new SplitText("#market-headline", { type: "chars, words" });
    let captionRef = new SplitText("#market-body", { type: "lines" });

    const splitRevert = () => {
      captionRef.revert();
    };
    const splitRevertHeadline = () => {
      titleRef.revert();
    };

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#market-flythrough"
          //markers: true
        }
      })
      .from(titleRef.chars, {
        duration: 0.65,
        x: 10,
        autoAlpha: 0,
        stagger: 0.05,
        onComplete: splitRevertHeadline
      })
      .from(
        captionRef.lines,
        {
          duration: 0.65,
          y: 20,
          autoAlpha: 0,
          stagger: 0.03,
          onComplete: splitRevert
        },
        ">"
      )
      .fromTo("#market-link", { opacity: 0 }, { opacity: 1 });
  }, {});

  const slidesoverlay = [
    {
      video: "portals/family-friendly/unlocked/Family_Section2_SandCastle",
      headline: "",
      bodyCopy: ""
    },
    {
      video: "portals/family-friendly/unlocked/Family_Section2_Aquabanas",
      headline: "",
      bodyCopy: ""
    }
  ];

  const handleVideo = () => {
    if (allVideosPlaying) {
      setAllVideosPlaying(false);
    } else {
      setAllVideosPlaying(true);
    }
  };

  return (
    <UnlockedPortalWrapper bgColorClass="bg-[#6470B5]" gaPrefix={`ff`}>
      <div className="absolute z-[99] mt-2 right-2">
        {" "}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => handleVideo()}
          onClick={() => handleVideo()}
          aria-label={(allVideosPlaying ? "Pause" : "Play") + " all videos"}
          className={"absolute top-0 right-0 block z-50 bg-dark-blue px-1"}
        >
          {!allVideosPlaying && (
            <span
              aria-hidden
              className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white"
            >
              play_arrow
            </span>
          )}
          {allVideosPlaying && (
            <span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white">
              pause
            </span>
          )}
        </div>
      </div>
      <UnlockedPortalIntro isPlaying={allVideosPlaying} portal="family-friendly" />
      <Overlay
        slides={slidesoverlay}
        autospeed={9700}
        isSlideshow={true}
        id={"slideoverlay"}
        className={"overlaybkg"}
      >
        <div className="flex flex-col items-center">
          {" "}
          <ModuleCopyHeadline theme={"dark"}>
            Lounge in our freshwater&nbsp;lagoon
          </ModuleCopyHeadline>
          <ModuleCopyBody theme={"dark"} className={``}>
            <div className="relative">
              <span className={`absolute left-0 top-0 w-full text-animated-1`}>
                At the heart of Starfish Lagoon is the largest freshwater lagoon in the Caribbean.*
                Choose your relaxation spot in one of our variety of lagoon-side cabanas, then swim,
                float, and chill all day in paradise with your favorite&nbsp;crew.
                <small className="block leading-[16px] text-[12px] mt-6 lg:mt-28">
                  *Based on publicly available data of surface area measurements as of 01/22/24.
                </small>
              </span>
            </div>
          </ModuleCopyBody>
        </div>
      </Overlay>
      <FlyThrough
        media={`video`}
        videoHeight={"h-screen"}
        videoName={`portals/family-friendly/unlocked/Family_Section3`}
        isPlaying={true}
        headline="Where little guests make a big&nbsp;splash"
        bodyCopy="Kids will soak it all in at Guppy Grotto, our coral-reef themed splash pad with 2-foot-deep wading pool and loads of fun water toys. Plus, with a nearby ice cream spot and a place for adults to grab a drink, everyone feels totally&nbsp;refreshed. "
      />
      <FlyThrough
        media={`image`}
        imgSrc={`${awsUrl}/media/portals/family-friendly/unlocked/sportscourt-r1`}
        isPlaying={false}
        headline="Have a ball on the Sports Court"
        bodyCopy="We’ll bring the gear, you bring the friendly competition! Go head-to-head with your friends and family in volleyball, basketball, pickleball, and&nbsp;more."
      ></FlyThrough>
      <FlyThrough
        media={`image`}
        videoHeight={"h-screen"}
        imgSrc={`${awsUrl}/media/portals/family-friendly/unlocked/beach-r1`}
        isPlaying={false}
        headline="Sun. Sand. Paradise."
        bodyCopy="In Starfish Lagoon’s dedicated beach area, a picture-perfect scene awaits. Relax on the soft white sand, float in the pristine waters, and feel the gentle Bahamian sea breeze on your face. Here, it’s pure&nbsp;paradise."
        link="Explore sailings to Celebration Key™&nbsp;»"
        linkId={`sun-link`}
      ></FlyThrough>
      <SideBySide mobileReverse={false} classes={"welcome-to-adult"}>
        <ModuleSideHalf className="bg-dark-blue">
          <div className="w-full h-full relative overflow-hidden">
            <div
              className="absolute bg-cover h-full w-full bg-bottom"
              style={{
                backgroundImage:
                  "url(" +
                  awsUrl +
                  "/media/portals/adult-friendly/unlocked/super-villa-crop.jpg?v=1)"
              }}
            />
          </div>
        </ModuleSideHalf>
        <ModuleSideHalf className="bg-white">
          <div className="px-4 md:px-12 text-center max-w-[85%]">
            <img
              className="mx-auto mb-3 lg:mb-[25px] w-[70px] lg:w-[77px]"
              src={`${awsUrl}/media/portals/adult-friendly/icon.svg`}
              alt=""
            />
            <ModuleCopyHeadline theme={"dark"}>Visit Calypso&nbsp;Lagoon </ModuleCopyHeadline>
            <ModuleCopyBody theme={"dark"}>
              Our adult-friendly Portal pairs a lively, high-energy vibe with plenty
              of&nbsp;relaxation.
              <div className="block mt-[30px]">
                {" "}
                <Link
                  to={"/portals/calypso-lagoon"}
                  className="font-semibold"
                  onClick={() => {
                    triggerGAEvent("ff_calypso-lagoon");
                  }}
                >
                  <Button color={`bg-[#052049]`} className={`px-16 lg:px-24 text-white`}>
                    Explore now
                  </Button>
                </Link>
              </div>
            </ModuleCopyBody>
          </div>
        </ModuleSideHalf>
      </SideBySide>
    </UnlockedPortalWrapper>
  );
};

export default Retail;
