import React, { useEffect, useState } from "react";
import { padStart } from "lodash";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import FocusTrap from "focus-trap-react";

import { useSweepstakesContext } from "../../context/SweepstakesContext";
import { useUserContext } from "../../context/UserContext";
import { useForm } from "react-hook-form";
import { postRegistration } from "../../services/apiClient";
import { scrollTo, triggerFloodLightEvent, triggerGAEvent } from "../../helpers";

import FormInputGroup from "../../common/Elements/FormInputGroup";
import FormCheckboxGroup from "../../common/Elements/FormCheckboxGroup";
import Button from "../../common/Elements/Button";
import InputError from "../../common/Elements/InputError";
import Input from "../../common/Elements/Input";
import Label from "../../common/Elements/Label";
import { useEscape } from "../../hooks/useEscape";

const RegistrationForm = () => {
  const { setEnteredPromo, setUserId, quizResultId, isTrade, vifpNumber, setVifpNumber } =
    useUserContext();
  const { setShowSection, userEmail, captcha, gaCodes } = useSweepstakesContext();
  const [searchParams] = useSearchParams();

  const [showVIFP, setShowVIFP] = useState(false);

  useEffect(() => {
    if (Object.keys(captcha).length === 0) {
      setShowSection("email");
    }
  }, [captcha, setShowSection]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    setError,
    clearErrors,
    watch,
    setValue
  } = useForm({
    defaultValues: {
      email: userEmail,
      mathToken: captcha.token,
      optin: true,
      vifp_num: vifpNumber ? vifpNumber : searchParams.get("vifpNum")
    }
  });

  const validation = {
    first_name: {
      required: "First Name is required",
      pattern: {
        value: /^[A-Za-z\s\-']+$/,
        message: "Must be letters, apostrophe, or dash."
      }
    },
    last_name: {
      required: "Last Name is required",
      pattern: {
        value: /^[A-Za-z\s\-']+$/,
        message: "Must be letters, apostrophe, or dash."
      }
    },
    email: {
      required: "Email is required",
      pattern: {
        value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        message: "Email is invalid"
      }
    },
    phone_number: {
      required: "Phone is required",
      pattern: {
        value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
        message: "Phone is invalid"
      }
    },
    dob_month: {
      required: "Month is required",
      min: { value: 1, message: "Month is required" },
      max: { value: 12, message: "Month is required" },
      validate: {
        validDate: (value, formValues) => {
          if (value && formValues.dob_day && formValues.dob_year) {
            const daysInMonth = new Date(formValues.dob_year, value, 0).getDate();
            if (formValues.dob_day > daysInMonth) {
              return "Date is invalid";
            }
          }
        },
        olderThan21: (value, formValues) => {
          if (value && formValues.dob_day && formValues.dob_year) {
            const year = formValues.dob_year;
            const month = padStart(formValues.dob_month, 2, "0");
            const day = padStart(formValues.dob_day, 2, "0");

            const age = dayjs().diff(`${year}-${month}-${day}`, "year");
            return age >= 21 || "Must be at least 21 years old";
          }
        }
      }
    },
    dob_day: {
      required: "Day is required",
      min: { value: 1, message: "Day is required" },
      max: { value: 31, message: "Day is required" }
    },
    dob_year: {
      required: "Year is required",
      min: { value: 1920, message: "Year is required" },
      max: { value: 2003, message: "Year is required" }
    },
    vifp_num: {
      validate: {
        lessThanTen: (v) => {
          if (v) {
            return parseInt(v) <= 9999999999 || "VIFP Number must be valid";
          }
        }
      }
    },
    rules: { required: "You must agree to the rules" },
    mathAnswer: { required: "You must answer the math question" }
  };

  // Format phone number
  const phoneNumber = watch("phone_number");
  useEffect(() => {
    if (phoneNumber) {
      const x = phoneNumber.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      setValue("phone_number", !x[2] ? x[1] : "" + x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : ""));
    }
  }, [phoneNumber, setValue]);

  // Format zip code
  const numsOnlyMask = (evt) => {
    let charCode = evt.which ? evt.which : evt.keyCode;

    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105) &&
      charCode !== 46
    ) {
      evt.preventDefault();
    } else {
      return true;
    }
  };

  useEscape(() => setShowVIFP(false));

  const [submitting, setSubmitting] = useState(false);
  const submit = (data) => {
    if (!submitting) {
      data.rules = Boolean(data.rules);
      data.optin = Boolean(data.optin);
      data.gaCodes = gaCodes;
      data.quizResultId = quizResultId;
      data.dob = `${data.dob_year}-${padStart(data.dob_month, 2, "0")}-${padStart(
        data.dob_day,
        2,
        "0"
      )}`;
      data.is_trade = isTrade;

      triggerGAEvent("sweeps_submit");
      scrollTo("sweeps");
      setSubmitting(true);

      postRegistration(data)
        .then((response) => {
          if (response.status === 201) {
            // Entry completed successfully

            setShowSection(response.data.validEntry ? "thank-you" : "already-entered");

            if (response.data.validEntry) triggerGAEvent("registered");
            if (response.data.validEntry) triggerGAEvent("entered");
            if (response.data.validEntry && !isTrade) {
              triggerFloodLightEvent("carni047+unique");

              document.getElementById("ttd-pixel").innerHTML =
                '<img height="1" width="1" style="border-style:none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=trl7bi1&ct=0:4p7vv7h&fmt=3"/>';
            }

            setUserId(response.data.userId);
            setEnteredPromo(true);
            setVifpNumber(data.vifp_num);
          } else {
            // Something went wrong
            setError("general", { message: "Something went wrong. Please refresh try again." });
          }
          setSubmitting(false);
        })
        .catch(({ response }) => {
          if (
            response.status === 422 &&
            response.data.errors &&
            Object.keys(response.data.errors).length > 0
          ) {
            // Backend validation errors
            Object.keys(response.data.errors).forEach((key) => {
              setError(key, { type: "server", message: response.data.errors[key][0] });
            });
          } else {
            // Something went wrong
            setError("general", { message: "Something went wrong. Please refresh try again." });
          }
          setSubmitting(false);
        });
    }
  };

  return (
    <div className="max-w-4xl py-16 pb-8 px-4 mx-auto leading-tight text-dark-blue">
      <h2 className="text-[28px] lg:text-[32px] leading-[30px] lg:leading-[36px]">
        Enter now for your chance to{` `}
        <strong className="inline lg:block">
          win a Carnival cruise that visits Celebration&nbsp;Key{" "}
          <sup className="text-[12px] -top-[10px] -ml-[6px] lg:-ml-[5px] lg:text-[16px] lg:-top-[10px]">
            &trade;
          </sup>
        </strong>
      </h2>
      <p className="text-[19px] py-4">
        Complete the form and you could be one of the first guests to sail for&nbsp;paradise.
      </p>
      <form
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(submit)(e);
        }}
        className="max-w-3xl mx-auto text-left"
      >
        <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-4">
          <FormInputGroup
            id="reg_form_first_name"
            label="First Name"
            auto-complete="given_name"
            {...register("first_name", validation.first_name)}
            error={formErrors.first_name}
            aria-required="true"
            autoFocus
          />
          <FormInputGroup
            id="reg_form_last_name"
            label="Last Name"
            auto-complete="family_name"
            {...register("last_name", validation.last_name)}
            error={formErrors.last_name}
            aria-required="true"
          />
          <FormInputGroup
            id="reg_form_email"
            label="Email"
            {...register("email", validation.email)}
            auto-complete="email"
            error={formErrors.email}
            aria-required="true"
          />
          <FormInputGroup
            id="reg_form_phone_number"
            label="Phone"
            auto-complete="tel"
            {...register("phone_number", validation.phone_number)}
            error={formErrors.phone_number}
            onKeyDown={numsOnlyMask}
            aria-required="true"
          />
          <div>
            <Label htmlFor="reg_form_dob">Date of Birth</Label>
            <div className="flex">
              <FormInputGroup
                className="w-1/3"
                id="reg_form_dob_month"
                label="Month"
                labelHidden={"true"}
                auto-complete="bday-month"
                {...register("dob_month", validation.dob_month)}
                error={formErrors.dob_month}
                aria-required="true"
                options={[
                  { value: "", label: "MM" },
                  ...Array.from(Array(12).keys()).map((i) => ({
                    value: i + 1,
                    label: padStart(i + 1, 2, "0")
                  }))
                ]}
              />
              <FormInputGroup
                className="w-1/3 mx-2"
                id="reg_form_dob_day"
                label="Day"
                labelHidden={"true"}
                auto-complete="bday-day"
                {...register("dob_day", validation.dob_day)}
                error={formErrors.dob_day}
                aria-required="true"
                options={[
                  { value: "", label: "DD" },
                  ...Array.from(Array(31).keys()).map((i) => ({
                    value: i + 1,
                    label: padStart(i + 1, 2, "0")
                  }))
                ]}
              />
              <FormInputGroup
                className="w-1/3"
                id="reg_form_dob_year"
                label="Year"
                labelHidden={"true"}
                auto-complete="bday-year"
                aria-required="true"
                {...register("dob_year", validation.dob_year)}
                error={formErrors.dob_year}
                options={[
                  { value: "", label: "YYYY" },
                  ...Array.from(Array(83).keys()).map((i) => ({
                    value: 2003 - i,
                    label: 2003 - i
                  }))
                ]}
              />
            </div>
            <InputError>{formErrors.dob && formErrors.dob.message}</InputError>
          </div>
          <div>
            <FormInputGroup
              id="reg_form_vifp_num"
              label="VIFP Number (optional)"
              {...register("vifp_num", validation.vifp_num)}
              error={formErrors.vifp_num}
              onKeyDown={numsOnlyMask}
            />
            <div className="flex justify-end relative">
              <button
                type="button"
                aria-label="Find out what VFIP number is - opens a tooltip"
                className="text-[15px] text-blue underline"
                onClick={() => {
                  setShowVIFP(!showVIFP);
                  triggerGAEvent("sweeps_what");
                }}
              >
                (What’s This?)
              </button>
              {showVIFP && (
                <FocusTrap focusTrapOptions={{ initialFocus: "#vfip-modal-close" }}>
                  <div className="absolute top-6 w-[300px] bg-white border border-blue rounded px-4 py-6 text-blue vfip-tooltip">
                    Earn points and get members-only fares and promotions. It's free to join! To
                    join now or to look up your VIFP number{" "}
                    <a
                      href="https://www.carnival.com/profilemanagement/accounts/register"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="link to look up your VIFP number (opens in a new window)"
                      onClick={() => setShowVIFP(!showVIFP)}
                    >
                      click here.
                    </a>
                    <button
                      id="vfip-modal-close"
                      type="button"
                      aria-label="Close"
                      className="absolute top-1 right-2"
                      autoFocus
                      onClick={() => setShowVIFP(!showVIFP)}
                    >
                      <span aria-hidden="true" className="material-symbols-rounded">
                        close
                      </span>
                    </button>
                  </div>
                </FocusTrap>
              )}
            </div>
          </div>
          <FormCheckboxGroup
            id="reg_form_rules"
            {...register("rules", validation.rules)}
            error={formErrors.rules}
            aria-required="true"
            required
            value={true}
            className="col-span-2"
          >
            I have read and agree to the terms of the{" "}
            <a
              href="/official-rules"
              target="_blank"
              rel="noreferrer"
              aria-label="rules, (link to view full rules - opens in a new window)"
              className={`inline underline hover:underline`}
              onClick={() => {
                triggerGAEvent("sweeps_rules");
              }}
              dangerouslySetInnerHTML={{ __html: `Official Rules` }}
            ></a>
            . I additionally attest that I am a legal resident of the 50 U.S., DC, Puerto Rico, or
            Canada (excluding Quebec), 21+.
          </FormCheckboxGroup>
          <FormCheckboxGroup
            id="reg_form_optin"
            {...register("optin", validation.optin)}
            error={formErrors.optin}
            value={true}
            className="col-span-2"
            defaultChecked={true}
          >
            I agree to the VIFP{" "}
            <a
              href="https://www.carnival.com/vifp/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
              aria-label="Terms and Conditions, (view the Terms and Conditions - opens in a new window)"
              className={`inline underline hover:underline`}
              onClick={() => {
                triggerGAEvent("sweeps_optin_terms");
              }}
              dangerouslySetInnerHTML={{ __html: `Terms and Conditions` }}
            ></a>{" "}
            and{" "}
            <a
              href="https://www.carnival.com/about-carnival/legal-notice/privacy-notice"
              target="_blank"
              rel="noreferrer"
              aria-label="Privacy Policy (view the Privacy Policy - opens in a new window)"
              className={`inline underline hover:underline`}
              onClick={() => {
                triggerGAEvent("sweeps_optin_privacy");
              }}
              dangerouslySetInnerHTML={{ __html: `Privacy Policy` }}
            ></a>{" "}
            for partnerships and would like to agree to receive emails for special offers and/or
            information from Carnival.
          </FormCheckboxGroup>
          <div className="col-span-2">
            <Label>
              Please correctly answer the provided mathematical question in order to proceed:
            </Label>
            <div className="mt-1 flex items-center justify-start flex-col md:flex-row">
              <img src={captcha.url} alt={`Math Captcha ${captcha.ariaLabel}`} />
              <Input
                aria-label={`Please correctly answer the provided mathematical question in order to proceed: ${captcha.ariaLabel}`}
                id="math-answer"
                {...register("mathAnswer", validation.mathAnswer)}
                error={formErrors.mathAnswer}
                aria-describedby="math-answer-error"
                maxLength={`10`}
                className="md:w-32 ml-4"
              />
            </div>
            <InputError id="math-answer-error" className="text-left">
              {formErrors.mathAnswer && formErrors.mathAnswer.message}
            </InputError>
          </div>
        </div>
        <InputError>{formErrors.general}</InputError>
        <div className="text-center mt-4">
          <Button disabled={isSubmitting} as="submit" className={`px-14 pt-1`}>
            Submit Entry
          </Button>
        </div>
      </form>
      <div className="text-blue text-sm mt-8">
        Questions about the promotion?{""}
        <a
          href="/contact-us"
          target="_blank"
          className="underline ml-2"
          aria-label="Click here to contact us (opens in a new window)"
          onClick={() => {
            triggerGAEvent("sweeps_contact-us");
          }}
          dangerouslySetInnerHTML={{ __html: `Contact us` }}
        ></a>{" "}
      </div>
    </div>
  );
};

export default RegistrationForm;
