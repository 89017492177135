import React, { useRef } from "react";
import { awsUrl, triggerGAEvent } from "../../helpers";
import { useUserContext } from "../../context/UserContext";
import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";
import { useGSAP } from "../../gsap/react";

gsap.registerPlugin(SplitText, useGSAP);

const mountedStyle = {
  animation: "inAnimation 300ms ease-in"
};
// const unmountedStyle = {
//   animation: "outAnimation 300ms ease-out",
//   animationFillMode: "forwards"
// };

const QuizAnswers = ({ answers }) => {
  const { isTrade } = useUserContext();
  const headlineRef = useRef();
  const headlineTl = useRef();

  useGSAP(() => {
    let titleRef = new SplitText("#answersRef", { type: "chars, words" });
    let captionRef = new SplitText(".lead", { type: "lines" });

    const splitRevert = () => {
      captionRef.revert();
    };

    headlineTl.current = gsap
      .timeline({
        // scrollTrigger: {
        //   start: "top 260px",
        //   trigger: "#carnival-ships"
        //   // markers: true
        // }
      })
      .from(
        titleRef.chars,
        {
          duration: 0.65,
          x: 10,
          autoAlpha: 0,
          stagger: 0.03
        },
        "<"
      )
      .from(
        captionRef.lines,
        {
          duration: 0.75,
          y: 20,
          autoAlpha: 0,
          stagger: 0.05,
          onComplete: splitRevert
        },
        ">"
      );
  }, {});

  const imgSet = answers.map(({ title, copy, media }, idx) => {
    return (
      <div
        className="relative results aspect-square lg:aspect-square overflow-hidden"
        key={`Set${title}`}
      >
        <div className="absolute text-white z-10 results--copy w-full flex items-center">
          <div className="results--copy-container z-20">
            <p className="text-tempo text-[30px] leading-[32px] lg:text-[45px] lg:leading-[54px] drop-shadow-2xl uppercase">
              {title}
            </p>
            <p className="text-[19px] transition-150 lg:px-[30px]">
              {copy}{" "}
              {media === "lagoon" && (
                <small className="block text-[11px] leading-[15px] mt-3 max-w-[270px] mx-auto lg:max-w-[300px]">
                  *Based on publicly available data of surface area measurements as of 01/22/24.
                </small>
              )}
            </p>
          </div>
        </div>

        <div className="results--overlay"></div>
        <img
          className="h-full w-auto lg:w-auto lg:h-full z-0"
          src={`${awsUrl}/media/your-day/${media}.jpg`}
          srcSet={`${awsUrl}/media/your-day/${media}@2x.jpg`}
          aria-hidden="true"
          alt={`${title} Background`}
        />
      </div>
    );
  });

  return (
    <div style={mountedStyle} className="relative">
      <h3
        id="answersRef"
        ref={headlineRef}
        className="mt-[38px] text-[30px] leading-[32px] lg:text-[45px] text-bg-dark-blue "
        aria-label="Sounds like an unforgettable day"
      >
        <span aria-hidden="true">
          Sounds like an <span className="block lg:inline">unforgettable&nbsp;day</span>
        </span>
      </h3>
      <p className="lead text-[19px] mt-[18px] lg:mt-[20px] px-6 lg:px-0 text-bg-dark-blue ">
        Based on your choices, here are 3 must-sees at Celebration Key<sup>&trade;</sup>.
      </p>

      <div className="grid grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 my-[26px] ">{imgSet}</div>

      <div className="mb-[38px]">
        <p className="text-[19px] text-dark-blue px-3 mb-3">
          You unlocked it… now start planning your trip to paradise and explore Carnival cruises
          that sail to Celebration Key<sup>&trade;</sup>.
        </p>

        {!isTrade ? (
          <a
            href={`https://www.carnival.com/cruise-search?pageNumber=1&numadults=2&ptport=CBK&pagesize=8&sort=fromprice&showBest=true&async=true&currency=USD&locality=1`}
            onClick={() => {
              triggerGAEvent("plan-result_explore-sailings");
            }}
            className="text-light-blue font-bold"
          >
            Explore Sailings&nbsp;»
          </a>
        ) : (
          <p className="text-center text-[19px] text-dark-blue">
            Contact your Travel Advisor today!
          </p>
        )}
      </div>
    </div>
  );
};

export default QuizAnswers;
