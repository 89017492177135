import React from "react";
import { ModuleSection } from "./Parts";
import PropTypes from "prop-types";

const SideBySide = ({ children, mobileReverse, classes }) => {
  const mobileReversed = mobileReverse !== true ? "flex-col" : "flex-col-reverse";

  return (
    <ModuleSection className={classes}>
      <div className={`flex ${mobileReversed}  lg:flex-row w-full h-full side-by-side ${classes}`}>
        {children}
      </div>
    </ModuleSection>
  );
};

SideBySide.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  mobileReverse: PropTypes.bool
};

export default SideBySide;
