import React from "react";
import PropTypes from "prop-types";

const ModuleSection = ({ children, className }) => {
  return <div className={`w-full h-screen relative overflow-hidden ${className}`}>{children}</div>;
};

ModuleSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
};

export default ModuleSection;
