import React, { forwardRef } from "react";

const Button = forwardRef((props, ref) => {
  return (
    <button
      {...props}
      className={`${props.className ? props.className : ""} ${
        props.color ? props.color : "bg-[#0680B5]"
      }  ${
        props.theme === "dark" ? "text-dark-blue" : "text-white"
      }  uppercase text-[20px] font-bold h-12 leading-none`}
    >
      {props.children}
    </button>
  );
});

export default Button;
