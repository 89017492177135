import React, { useState } from "react";
import { ModuleSection, ModuleAccordionPanel } from "./Parts";

const AccordionGallery = ({ panels, isPlaying }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [initiated, setInitiated] = useState(false);

  return (
    <ModuleSection>
      <ul className="flex lg:flex-row h-screen accordion">
        {panels.map((panel, index) => {
          return (
            <ModuleAccordionPanel
              panel={panel}
              isPlaying={isPlaying}
              isActive={index === activeIdx}
              isInitial={!initiated}
              setInitial={() => {
                setActiveIdx(index);
                setInitiated(true);
              }}
              setActive={() => {
                setActiveIdx(index);
                setInitiated(false);
              }}
            />
          );
        })}
      </ul>
    </ModuleSection>
  );
};

export default AccordionGallery;
