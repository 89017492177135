import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { awsUrl, triggerGAEvent } from "../../helpers";
import { useScrollDetectors } from "../../hooks/useScrollDetectors";

import { UnlockedPortalWrapper } from "../../components/UnlockedPortal/Parts";
import UnlockedPortalIntro from "../../components/UnlockedPortal/UnlockedPortalIntro";
import Button from "../../common/Elements/Button";
import { useUserContext } from "../../context/UserContext";

import { FlyThrough, SideBySide } from "../../components/UnlockedPortal/Modules";
import {
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleSideHalf
} from "../../components/UnlockedPortal/Modules/Parts";

const Welcome = () => {
  const handleVideo = () => {
    if (allVideosPlaying) {
      setAllVideosPlaying(false);
    } else {
      setAllVideosPlaying(true);
    }
  };

  const { setCurrentPage, setAllVideosPlaying, allVideosPlaying, isTrade } = useUserContext();
  setCurrentPage("paradize-plaza");

  useScrollDetectors("welcome-portal");

  useEffect(() => {
    document.title = "Carnival | Celebration Key™ | Paradise Plaza";
  }, []);

  return (
    <UnlockedPortalWrapper bgColorClass="portal-welcome bg-[#FFBD40]" gaPrefix={`pp`}>
      <div className="absolute z-[99] mt-2 right-2">
        {" "}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => handleVideo()}
          onClick={() => handleVideo()}
          aria-label={(allVideosPlaying ? "Pause" : "Play") + " all videos"}
          className={"absolute top-0 right-0 block z-50 bg-dark-blue px-1"}
        >
          {!allVideosPlaying && (
            <span
              aria-hidden
              className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white"
            >
              play_arrow
            </span>
          )}
          {allVideosPlaying && (
            <span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white">
              pause
            </span>
          )}
        </div>
      </div>
      <UnlockedPortalIntro isPlaying={allVideosPlaying} portal="welcome" />
      <FlyThrough
        videoName="portals/welcome/unlocked/flythrough"
        media={`image`}
        imgSrc={`${awsUrl}/media/portals/welcome/unlocked/section-2`}
        isPlaying={false}
        headline="Unlock your kind of&nbsp;fun"
        bodyCopy={
          isTrade ? (
            <>
              At Paradise Plaza decide how you'll make the most of your day at Celebration Key, from
              shopping and cabana rentals, to shore excursions and more, your adventure{" "}
              <span className="inline-block">begins here.</span>
            </>
          ) : (
            <>
              Whether you feel like shopping, lounging, or trying something new, making the most of
              your day at Celebration Key™ begins here. Cabana and daybed rentals, exciting shore
              excursions, and reservations for Pearl Cove Beach Club are now available{" "}
              <span className="inline-block">to book!</span>
            </>
          )
        }
        learnMoreButton={!isTrade}
      ></FlyThrough>
      <FlyThrough
        className={"bg-symbol"}
        videoName="portals/welcome/unlocked/suncastle"
        isPlaying={allVideosPlaying}
        media={`video`}
        welcome={true}
        bodyClass={`top-[1rem] lg:top-0`}
        videoHeight={`h-[50%] lg:h-full z-[20] absolute lg:relative lg:z-[1] w-full`}
        gradientTopperWelcome={true}
        headline="A giant symbol of&nbsp;paradise"
        bodyCopy="The Suncastle is our larger-than-life walkthrough sandcastle and the highest point at the center of Celebration Key™. Catch the view from the observation deck, then take the plunge down one of two racing water slides - the pink Flash Flamingo or the blue Mach III Marlin - that weave through and around the castle with exciting turns and a splashing photo finish!"
        link="Explore sailings to Celebration Key™&nbsp;»"
        gaTriggerEvent={`pp_explore-sailings`}
      ></FlyThrough>
      <SideBySide mobileReverse={false} classes={"welcome-to-adult"} bgColorClass="portal-welcome">
        <ModuleSideHalf className="bg-dark-blue">
          <div className="w-full h-full relative overflow-hidden">
            <div
              className="absolute bg-cover h-full w-full bg-bottom"
              style={{
                backgroundImage:
                  "url(" + awsUrl + "/media/portals/private-club/pearl-cove-bg@2x.jpg)"
              }}
            />
          </div>
        </ModuleSideHalf>
        <ModuleSideHalf className="bg-white">
          <div className="px-4 md:px-10 text-center max-w-[85%]">
            <img
              className="mx-auto mb-3 lg:mb-[25px] w-[70px] lg:w-[77px]"
              src={`${awsUrl}/media/portals/private-club/icon.svg`}
              alt=""
            />
            <ModuleCopyHeadline theme={"dark"}>
              Visit
              <br />
              Pearl Cove
            </ModuleCopyHeadline>
            <ModuleCopyBody theme={"dark"}>
              Our Adults-Only private club Portal is the ultimate beach club paradise for{" "}
              <span className="inline-block">guests 18+</span>
              <div className="block mt-[30px]">
                {" "}
                <Link
                  to={"/portals/pearl-cove"}
                  className="font-semibold"
                  onClick={() => {
                    triggerGAEvent("pp_private-portal");
                  }}
                >
                  <Button color={`bg-[#052049]`} className={`px-16 lg:px-24`}>
                    Explore now
                  </Button>
                </Link>
              </div>
            </ModuleCopyBody>
          </div>
        </ModuleSideHalf>
      </SideBySide>
    </UnlockedPortalWrapper>
  );
};

export default Welcome;
