import React from "react";
import PropTypes from "prop-types";

const ModuleSideTwoThirds = ({ children, className }) => {
  return (
    <div
      className={`w-full h-1/2 lg:h-full lg:w-2/3 flex items-center justify-center ${
        className && className
      }`}
    >
      {children}
    </div>
  );
};

ModuleSideTwoThirds.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string
};

export default ModuleSideTwoThirds;
