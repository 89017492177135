import React from "react";
import PropTypes from "prop-types";

const NavBarHamburger = ({ onClick, color = null }) => {
  return (
    <div className="w-[28px] h-[28px] self-center" role="button" aria-label="view nav">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={color ? color : "currentColor"}
        className="w-[28px] h-[28px]"
        onClick={onClick}
      >
        <path
          fillRule="evenodd"
          d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

NavBarHamburger.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string
};

export default NavBarHamburger;
