import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div id="error-page">
      <div className="container mx-auto text-white flex flex-col items-center justify-center h-screen">
        <h1 className="text-center text-tempo uppercase text-3xl font-bold my-3">Oops!</h1>
        <p className="text-[19px] text-center font-bold">
          Sorry, an unexpected error has occurred.
        </p>
        <p className="text-center">
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
}
