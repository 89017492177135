import React, { createContext, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

const SweepstakesContext = createContext();

export const SweepstakesProvider = ({ children }) => {
  const [showSection, setShowSection] = useState("email");
  const [userEmail, setUserEmail] = useState("");
  const [captcha, setCaptcha] = useState({});

  const [searchParams] = useSearchParams();

  const gaCodes = {
    campaign: searchParams.get("utm_campaign"),
    source: searchParams.get("utm_source"),
    medium: searchParams.get("utm_medium")
  };

  return (
    <SweepstakesContext.Provider
      value={{
        showSection,
        setShowSection,
        userEmail,
        setUserEmail,
        captcha,
        setCaptcha,
        gaCodes
      }}
    >
      {children}
    </SweepstakesContext.Provider>
  );
};

export function useSweepstakesContext() {
  const context = useContext(SweepstakesContext);

  if (!context) {
    throw new Error("useSweepstakesContext must be used within a SweepstakesProvider");
  }

  return context;
}
